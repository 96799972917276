import { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, Redirect, useParams, useLocation } from "react-router-dom";

import { firestore, doc, onSnapshot } from '../../helpers/firebase';

import Profile from "./views/Profile";
import Insights from "./views/Insights";
import Campaigns from "./views/Campaigns";
import Tips from "./views/Tips";
import Support from "./views/Support";
import Settings from "./views/Settings";
import ProgressIndicator from "../../components/ProgressIndicator";

const Store = ({ onData }) => {
    const { path, url } = useRouteMatch();
    const { storeId } = useParams();
    const { state } = useLocation();

    const [loading, setLoading] = useState(!state?.data);
    const [error, setError] = useState();
    const [data, setData] = useState(state?.data);

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(firestore, "stores", storeId), snap => {
            setData(snap.data());
            setLoading(false);
            if (onData) onData(snap.data());
        }, setError)

        return () => {
            if (onData) onData(null);
            unsubscribe();
        }
    }, [onData, storeId])

    // const StoreRoute = ({ path, Component }) => {
    //     console.log(url)
    //     return (
    //         <Route path={`${url}/${path}`} exact component={({ ...props }) => <Component {...props} data={data} />} />
    //     );
    // }

    if (loading) return <ProgressIndicator />;

    if (error) return <pre>{JSON.stringify(error, null, 4)}</pre>;

    if (!data) return <Redirect to="/" />;

    return (
        <div>
            <Switch>
                <Route path={path} exact >
                    <Redirect to={`${url}/profile`} />
                </Route>

                <Route path={`${url}/profile`} exact render={props => <Profile {...props} data={data} storeId={storeId} />} />
                <Route path={`${url}/insights`} exact render={props => <Insights {...props} data={data} storeId={storeId} />} />
                <Route path={`${url}/campaigns`} exact render={props => <Campaigns {...props} data={data} storeId={storeId} />} />
                <Route path={`${url}/tips`} exact render={props => <Tips {...props} data={data} storeId={storeId} />} />
                <Route path={`${url}/support`} exact render={props => <Support {...props} data={data} storeId={storeId} />} />
                <Route path={`${url}/settings`} exact render={props => <Settings {...props} data={data} storeId={storeId} />} />
            </Switch>
        </div>
    )
}

export default Store;
