import { Box, Button, Link as MuiLink } from "@mui/material"
import { Link, Switch, Route, useRouteMatch } from "react-router-dom";

import Footer from "./Footer";

import About from "./views/About";
import Contact from "./views/Contact";
import Homepage from "./views/Homepage";
import HowTo from "./views/HowTo";

const Home = () => {
    const { path, url } = useRouteMatch();

    return (
        <Box sx={{ height: '100vh', m: 0, display: 'flex', flexDirection: 'column' }} >
            <Box sx={{ p: 2, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }} >
                <Link to={path} >
                    <img alt="logo" src="/images/logo.svg" style={{ height: 50, cursor: 'pointer' }} />
                </Link>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                    <MuiLink variant="h6" sx={{ marginRight: 4 }} underline="hover" component={Link} to={path} >Hjem</MuiLink>
                    <MuiLink variant="h6" sx={{ marginRight: 4 }} underline="hover" component={Link} to="/about" >Om membre</MuiLink>
                    <MuiLink variant="h6" sx={{ marginRight: 4 }} underline="hover" component={Link} to="/contact" >Kontakt</MuiLink>
                    <MuiLink variant="h6" underline="hover" component={Link} to="/how-to" >Spørsmål og svar</MuiLink>
                </Box>

                <Box>
                    <Button sx={{ width: 150, marginRight: 2 }} size="large" variant="outlined" component={Link} to="/register" >Registrer</Button>
                    <Button sx={{ width: 150 }} size="large" variant="contained" component={Link} to="/login" >Logg inn</Button>
                </Box>
            </Box>

            {/* <Grid container spacing={1} alignItems="center" justifyContent="space-between" sx={{ p: 2 }} >
                <Grid item xs={0} sm={0} md={1} />

                <Grid item xs={12} sm={1} >
                    <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Link to={path} >
                            <img alt="logo" src="/images/logo.svg" style={{ height: 50, cursor: 'pointer' }} />
                        </Link>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={3} >
                    <Box sx={{ gap: 4, display: 'flex', justifyContent: 'space-evenly' }} >
                        <MuiLink underline="hover" component={Link} to="/about" >Om membre</MuiLink>
                        <MuiLink underline="hover" component={Link} to="/contact" >Kontakt</MuiLink>
                        <MuiLink underline="hover" component={Link} to="/how-to" >Hvordan bruke membre</MuiLink>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={3} >
                    <Box sx={{ gap: 4, display: 'flex', justifyContent: 'space-evenly' }} >
                        <Button sx={{ width: 150 }} size="large" variant="outlined" component={Link} to="/register" >Registrer</Button>
                        <Button sx={{ width: 150 }} size="large" variant="contained" component={Link} to="/login" >Logg inn</Button>
                    </Box>
                </Grid>
            </Grid> */}

            <Box sx={{ marginTop: 10 }} >
                <Switch>
                    <Route path={path} exact component={Homepage} />
                    <Route path={`${url}about`} exact component={About} />
                    <Route path={`${url}contact`} exact component={Contact} />
                    <Route path={`${url}how-to`} exact component={HowTo} />
                </Switch>
            </Box>

            <Box sx={{ marginTop: 'auto' }} >
                <Footer />
            </Box>
        </Box>
    )
}

export default Home;
