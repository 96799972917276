import { useState } from "react";
import { Typography, IconButton, Dialog, DialogTitle, useMediaQuery, useTheme, Link, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { firestore, doc, updateDoc, arrayUnion, storage, ref, uploadBytes, getDownloadURL, arrayRemove } from '../../helpers/firebase'
import CampaignForm from './CampaignForm';
import Modal from '../../components/Modal';
import CampaignPreview from "../../components/campaign/CampaignPreview";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, loading, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={loading ? null : onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    size="large">
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const CampaignModal = ({ storeId, storeData, open, handleClose, campaign }) => {
    const isEdit = !!campaign;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [preview, setPreview] = useState();

    const handleSave = async (data, image) => {
        setLoading(true);
        setError(null);

        try {
            if (data.imageOption === "upload" && !!image) {
                let imageUrl;

                if (typeof image === "object") {
                    const storageRef = ref(storage, `campaignImages/${storeId}/${image.name}`);
                    await uploadBytes(storageRef, image);
                    imageUrl = await getDownloadURL(storageRef);
                } else {
                    imageUrl = image;
                }

                data.image = imageUrl;
            }

            if (isEdit) {
                await updateDoc(doc(firestore, "stores", storeId), { campaigns: arrayRemove(...[campaign]) });
            }

            await updateDoc(doc(firestore, "stores", storeId), { campaigns: arrayUnion(...[data]) });

            setLoading(false);
            handleClose();

            enqueueSnackbar("Lagret!", { variant: "success" });
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    }

    const handlePreview = (data, image) => {
        setPreview({ ...data, image });
    }

    return (
        <div>
            <BootstrapDialog
                fullScreen={fullScreen}
                maxWidth="md"
                scroll="paper"
                // onClose={handleClose}
                aria-labelledby="new-campaign"
                open={open}
            >
                <BootstrapDialogTitle id="new-campaign" onClose={handleClose} loading={loading} >
                    {isEdit ? "Rediger kampanje" : "Ny kampanje"}
                </BootstrapDialogTitle>

                <Typography sx={{ marginBottom: 2 }} textAlign="center" variant="body2" >
                    Trenger du veiledning? Trykk <Link component="button" variant="body2" onClick={console.log} >her</Link>
                </Typography>

                <CampaignForm onSave={handleSave} loading={loading} campaign={campaign} onPreview={handlePreview} />

                {error && <Typography color="red" >Noe gikk galt</Typography>}
            </BootstrapDialog>

            {!!preview && (
                <Modal
                    id="campaign-preview"
                    open={!!preview}
                    handleClose={() => setPreview(null)}
                    maxWidth="xs"
                    title="Slik vil kampanjen se ut i appen"
                >
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <CampaignPreview
                            campaign={preview}
                            store={storeData}
                        />
                    </Box>
                </Modal>
            )}
        </div>
    )
}

export default CampaignModal;
