import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';

import confirmDialog from '../../helpers/confirmDialog';
import { functions, httpsCallable } from '../../helpers/firebase';

const Users = () => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [updateToggle, setUpdateToggle] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);

            const call = httpsCallable(functions, "getUsers");

            try {
                const res = await call();
                setUsers(res.data.users);
            } catch (error) {
                console.log(error);
            }

            setLoading(false);
        }

        fetchUsers();
    }, [updateToggle])

    const setUserIsAdmin = async (uid, isAdmin) => {
        const shouldContinue = await confirmDialog("Er du sikker?");

        if (shouldContinue) {
            setLoading(true);

            const call = httpsCallable(functions, "setUserIsAdmin");

            try {
                await call({ uid, isAdmin });
                setUpdateToggle(!updateToggle);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    }

    const columns = [
        {
            field: 'uid',
            headerName: 'ID',
            width: 100,
        },
        {
            field: 'displayName',
            headerName: 'Navn',
            flex: 1,
            valueGetter: params => params.value || "----",
        },
        {
            field: 'phoneNumber',
            headerName: 'Nummer',
            flex: 1,
            valueGetter: params => params.value || "----",
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            valueGetter: params => params.value || "----",
        },
        {
            field: 'actions',
            headerName: ' ',
            flex: 1,
            renderCell: params => {
                const isAdmin = params.row.customClaims?.isAdmin;
                const uid = params.row.uid;
                const isAnon = !params.row.email;

                if (isAnon) return null;

                return (
                    <strong>
                        <Button onClick={() => setUserIsAdmin(uid, !isAdmin)} >{isAdmin ? "Fjern som admin" : "Set som admin"}</Button>
                    </strong>
                );
            },
        },
    ];

    return (
        <div>
            {Array.isArray(users) && (
                <div style={{ height: '80vh', width: '100%' }}>
                    <DataGrid
                        loading={loading}
                        rows={users.map(user => ({ ...user, id: user.uid }))}
                        columns={columns}
                        autoPageSize
                        pagination
                        checkboxSelection
                        disableSelectionOnClick
                    />
                </div>
            )}
        </div>
    )
}

export default Users;
