import { Fragment, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { filterCampaigns } from "../../../../helpers/utils";
import CampaignInsights from './CampaignInsights';

const MainAccordion = ({ title, campaigns, store, storeId }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandedChange = (panel) => (_, isExpanded) => setExpanded(isExpanded ? panel : false);

    return (
        <Accordion defaultExpanded={true} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${title}-panel-content`}
                id={`${title}-panel-header`}
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }} >{title}</Typography>
                <Typography sx={{ color: 'text.secondary' }} >({campaigns.length})</Typography>
            </AccordionSummary>

            <AccordionDetails>
                {campaigns.length === 0 && (
                    <Typography sx={{ color: 'text.secondary' }} >Ingen kampanjer</Typography>
                )}

                {campaigns.map(campaign => {
                    const id = campaign.id;
                    const image = campaign.imageOption === "own" ? store.image : campaign.image;
                    const isExpanded = expanded === id;

                    return (
                        <Accordion key={id} sx={{ backgroundColor: isExpanded ? "#eaf5ed" : null }} expanded={isExpanded} onChange={handleExpandedChange(id)} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`${title}-${id}-panel-content`}
                                id={`${title}-${id}-panel-header`}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }} >
                                    <img
                                        alt="campaign logo"
                                        src={image}
                                        style={{ width: 40, height: 40, objectFit: 'cover', marginRight: 16 }}
                                    />
                                    <Typography>{campaign.text}</Typography>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails>
                                <CampaignInsights storeId={storeId} campaign={campaign} expanded={isExpanded} />
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </AccordionDetails>
        </Accordion>
    );
}

const Campaigns = ({ data, storeId }) => {
    const { publishedCampaigns, expiredCampaigns } = filterCampaigns(data?.campaigns);

    return (
        <Fragment>
            <MainAccordion title="AKTIVE KAMPANJER" campaigns={publishedCampaigns} store={data} storeId={storeId} />
            <MainAccordion title="TIDLIGERE KAMPANJER" campaigns={expiredCampaigns} store={data} storeId={storeId} />
        </Fragment>
    )
}

export default Campaigns;
