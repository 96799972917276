import { Fragment } from 'react';
import { Typography, Box } from '@mui/material';
import ImageTextRow from '../../../../components/ImageTextRow';

const About = () => {
    return (
        <Fragment>
            <Box
                sx={{
                    // backgroundImage: "url(/images/oval.svg)",
                    // backgroundSize: 'contain',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundPosition: 'center',
                    // // height: 652,
                    // // width: 1013,
                    position: 'relative',
                    maxWidth: 1000,
                    margin: '0px auto',
                }}
            >
                <img alt="design" src="/images/oval.svg" style={{ width: '100%', zIndex: 2 }} />
                <Box sx={{ position: 'absolute', top: 100, left: 0, right: 0, zIndex: 2 }} >
                    <Typography variant="h3" align="center" color="primary" >Om membre</Typography>
                </Box>
                <Box sx={{ position: 'absolute', top: 200, left: 8, right: 8, zIndex: 2 }} >
                    <Typography variant="h5" align="center" >Membre er en mobil-app som gjør det mulig for virksomheter å annonsere kampanjer direkte til sine kunder på en brukervennlig og innbydende måte!</Typography>
                </Box>
                <Box sx={{ position: 'absolute', bottom: 128, left: 0, right: 0, textAlign: 'center', zIndex: 1 }} >
                    <img alt="logo" src="/images/logo.svg" style={{ width: '30%' }} />
                </Box>
            </Box>

            <Typography variant="h4" align="center" marginY={8} >Hvordan fungerer membre-appen for bedriften og brukeren?</Typography>

            <ImageTextRow
                title="Brukerne har sine egne profiler"
                text="På denne profilen har brukerne muligheten til å legge til din bedrift som en del av sine medlemsskap, og derfor få varsling om kampanjer som bedriften publiserer"
                imgSrc="/images/appScreenshot2.png"
                imgAlt="appScreenshot"
            />

            <ImageTextRow
                isImgLeft
                title="Bedriften har sin egen side"
                text="På denne siden vil bedriften kunne presentere nøkkelinformasjon, og publisere sine kampanjer"
                imgSrc="/images/appScreenshot3.png"
                imgAlt="appScreenshot2"
            />

            <ImageTextRow
                title="Kunden bruker ønsket kampanje"
                text="Kundene tar i bruk ønsket kampanje ved å vise dette fysisk i butikken, eller ved å ta i bruk koden som presenteres i kampanjedetaljene"
                imgSrc="/images/appScreenshot4.png"
                imgAlt="appScreenshot3"
            />
        </Fragment>
    )
}

export default About;
