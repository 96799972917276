const _datesAreOnSameDay = (first, second) =>
    !first || !second ? false :
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();


const _getLastDays = days => {
    return Array.from(Array(days), (_, x) => x).map((n) => {
        let d = new Date();
        d.setDate(d.getDate() - n);

        // d.setHours(0);
        // d.setMinutes(0);
        // d.setSeconds(0);

        return d;
    }).reverse();
}

export const getMemberDate = member => {
    if (!member?.date) return null;

    let d = new Date(member.date._seconds * 1000);
    // d.setHours(0);
    // d.setMinutes(0);
    // d.setSeconds(0);

    return d;
}

export const getChartData = (members, daysToRender = 30) => {
    if (!Array.isArray(members)) return [];

    const days = _getLastDays(daysToRender);

    return days.map(day => {
        const filteredMembers = members.filter(member => _datesAreOnSameDay(getMemberDate(member), day));

        return [day.getTime(), filteredMembers.length];
    })
}