import { useState, useEffect } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';

import { functions, httpsCallable } from '../../../../helpers/firebase';
import ProgressIndicator from '../../../../components/ProgressIndicator';

const UserList = ({ storeId }) => {
    const [loading, setLoading] = useState(false);
    const [users, setusers] = useState();

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);

            const call = httpsCallable(functions, "getStoreAdmins");

            try {
                const res = await call({ storeId });
                setusers(res?.data);
            } catch (error) {
                console.log(error);
            }

            setLoading(false);
        }

        fetchUsers();
    }, [storeId])

    return (
        <div>
            {loading && <ProgressIndicator />}

            <List>
                {!loading && users?.map(user => (
                    <ListItem key={user.userId} disablePadding >
                        <ListItemText primary={user.email} />
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

export default UserList;
