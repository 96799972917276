import { useState, useEffect } from 'react';
import { Button, Typography, TableContainer, Table, TableRow, TableHead, Paper, TableCell, TableBody } from '@mui/material';

import { firestore, onSnapshot, collection, query, where } from '../../../../helpers/firebase';
import useAddUser from './hooks/useAddUser';

const Invitations = ({ storeId }) => {
    const [invitations, setInvitations] = useState([]);
    const [error, setError] = useState();
    const { loading, errorText, handleAdd } = useAddUser(storeId);

    useEffect(() => {
        const q = query(collection(firestore, `storeInvitations/${storeId}/invitations`), where("accepted", "==", false));

        const unsubscribe = onSnapshot(q, snap => {
            setInvitations(snap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        }, setError)

        return () => unsubscribe();
    }, [storeId])

    return (
        <div>
            {error && <pre>{JSON.stringify(error, null, 4)}</pre>}

            {errorText && <Typography color="red" >{errorText}</Typography>}

            {Array.isArray(invitations) && (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="invitations table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Sendt</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invitations.map((invitation) => (
                                <TableRow
                                    key={invitation.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {invitation.email}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(invitation.createdAt.seconds * 1000).toLocaleString("nb")}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disabled={loading} onClick={() => handleAdd(invitation.email, true)} >Send på nytt</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default Invitations;
