import { useState } from 'react';
import { Box, Typography, Link as MuiLink, Grid, TextField, Checkbox, Button, FormControlLabel, InputAdornment, IconButton, Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';
import { Link, } from "react-router-dom";

import { firestore, setDoc, collection, doc, serverTimestamp } from '../../helpers/firebase'
import CategorySelect from '../../components/CategorySelect';
import RegisterRequestSent from './RegisterRequestSent';
import ZipField from '../../components/ZipField';
import PhoneField from '../../components/PhoneField';

const Register = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [name, setName] = useState("");
    const [orgNr, setOrgNr] = useState("");
    const [category, setCategory] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");
    const [address2, setAddress2] = useState("");
    const [zip2, setZip2] = useState("");
    const [city2, setCity2] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [requestSent, setRequestSent] = useState(false);

    const [countyName, setCountyName] = useState(null);
    const [countyNumber, setCountyNumber] = useState(null);

    const handleSend = async () => {
        setLoading(true);
        setError(null);

        const docRef = doc(collection(firestore, "storeRequests"));
        const data = {
            name,
            orgNr,
            category,
            email,
            phone,
            address,
            zip,
            city: city || null,
            address2,
            zip2,
            city2: city2 || null,
            accepted: false,
            rejected: false,
            createdAt: serverTimestamp(),
            countyName: countyName || null,
            countyNumber: countyNumber || null,
        };

        try {
            await setDoc(docRef, data);
            setRequestSent(true);
        } catch (err) {
            setError(err)
        }

        setLoading(false);
    }

    const isValid = !!name && !!email && !!phone && !!address && !!zip && !!city && termsAccepted;

    if (requestSent) return <RegisterRequestSent />;

    const handleAddress = address => {
        setCity(address?.poststed);
        setCountyName(address?.kommunenavn);
        setCountyNumber(address?.kommunenummer);
    }

    const handleAddress2 = address => {
        setCity2(address?.poststed);
        // setCountyName2(address?.kommunenavn);
        // setCountyNumber2(address?.kommunenummer);
    }

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'end', p: 4 }} >
                <Typography>Allerede bruker? <MuiLink component={Link} to="/login" replace >Logg inn</MuiLink></Typography>
            </Box>

            <Grid container spacing={2} >
                <Grid item xs={0} sm={2} />
                <Grid item xs={12} sm={8} >
                    <Typography variant="h3" align="center" sx={{ marginBottom: 4 }} color="primary" >Registrering av din virksomhet</Typography>

                    <Typography sx={{ marginBottom: 8 }} variant="h5" align="center" >
                        For oss er det viktig at virksomhetene i appen er autentiske og ønsker å delta på en positiv måte, og derfor ser
                        vi på det som nødvendig at vi må godkjenne virksomheten før den blir synlig i appen.
                        Vi vil håndtere søknaden så snart som mulig.
                    </Typography>

                    <Typography variant="h5" color="primary" >Informasjon om virksomheten</Typography>

                    <Typography sx={{ marginBottom: 4 }} variant="h5" >Dette er informasjon som også vil være synlig i appen, og kan endres senere.</Typography>

                    <Grid container spacing={2} >
                        <Grid item xs={12} >
                            <TextField fullWidth required label="Navn på virksomheten" disabled={loading} value={name} onChange={e => setName(e.target.value)} />
                        </Grid>

                        <Grid item xs={6} >
                            <TextField
                                fullWidth
                                label="Organisasjonsnummer"
                                disabled={loading}
                                value={orgNr}
                                onChange={e => setOrgNr(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            <Tooltip title="Hjelp" >
                                                <IconButton edge="end" size="large">
                                                    <Info />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} >
                            <CategorySelect value={category} onChange={e => setCategory(e.target.value)} />
                        </Grid>

                        <Grid item xs={6} >
                            <TextField fullWidth required label="E-postadresse" disabled={loading} value={email} onChange={e => setEmail(e.target.value)} />
                        </Grid>

                        <Grid item xs={6} >
                            <PhoneField fullWidth required label="Telefonnummer" disabled={loading} value={phone} onChange={value => setPhone(value)} />
                        </Grid>

                        <Grid item xs={6} >
                            <TextField
                                fullWidth
                                required
                                label="Postadresse"
                                disabled={loading}
                                value={address}
                                onChange={e => setAddress(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            <Tooltip title="Hjelp" >
                                                <IconButton edge="end" size="large">
                                                    <Info />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={3} >
                            <ZipField fullWidth required label="Postnummer" disabled={loading} value={zip} onChange={e => setZip(e.target.value)} onAddress={handleAddress} />
                        </Grid>

                        <Grid item xs={3} >
                            <TextField fullWidth required inputProps={{ readOnly: true }} label="Sted" disabled={loading} value={city || ""} />
                        </Grid>

                        <Grid item xs={6} >
                            <TextField
                                fullWidth
                                label="Besøksadresse"
                                disabled={loading}
                                value={address2}
                                onChange={e => setAddress2(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            <Tooltip title="Hjelp" >
                                                <IconButton edge="end" size="large">
                                                    <Info />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={3} >
                            <ZipField fullWidth label="Postnummer" disabled={loading} value={zip2} onChange={e => setZip2(e.target.value)} onAddress={handleAddress2} />
                        </Grid>

                        <Grid item xs={3} >
                            <TextField fullWidth inputProps={{ readOnly: true }} label="Sted" disabled={loading} value={city2 || ""} />
                        </Grid>

                        <Grid item xs={12} >
                            <Typography>* Må fylles ut</Typography>
                        </Grid>

                        <Grid item xs={12} >
                            <Box sx={{ display: 'flex', justifyContent: 'end' }} >
                                <FormControlLabel
                                    control={<Checkbox checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} />}
                                    label={<span>Jeg/vi har forstått og godtar <MuiLink target="_blank" component={Link} to="/terms" >vilkårene</MuiLink></span>}
                                />

                                <Button variant="contained" disabled={!isValid} onClick={handleSend} >Send søknad</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={0} sm={2} />
            </Grid>

            {error && <pre>{JSON.stringify(error, null, 4)}</pre>}
        </div>
    );
}

export default Register;
