
import { Typography, Box, Card, CardContent, CardActions } from '@mui/material';

const CampaignPreview = ({ campaign, store, actions }) => {
    const image = campaign.imageOption === "own" ? store.image : campaign.image;

    return (
        <Card sx={{ width: 250 }} >
            <CardContent sx={{ height: 330, p: 0, paddingBottom: '0px !important' }} >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
                    <Box sx={{ flex: 1, height: 200 }} >
                        <img
                            alt="campaign logo"
                            src={typeof image === "object" ? URL.createObjectURL(image) : image}
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                    </Box>

                    <Box sx={{ padding: '16px' }} >
                        <Typography variant="h6" >{store.name}</Typography>

                        <Typography variant="body1" >{campaign.text}</Typography>
                    </Box>
                </Box>
            </CardContent>
            {actions && (
                <CardActions>
                    {actions}
                </CardActions>
            )}
        </Card>
    )
}

export default CampaignPreview;
