import { useState } from "react";
import { useSnackbar } from 'notistack';

import { functions, httpsCallable } from '../../../../../helpers/firebase';

const useAddUser = (storeId, onSuccess) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const handleAdd = async (email, resend = false) => {
        setLoading(true);
        setError(null);

        const call = httpsCallable(functions, "addStoreAdmin");

        try {
            await call({ storeId, email, resend });

            setLoading(false);
            if (onSuccess) onSuccess();
            enqueueSnackbar(`En mail har blitt sendt til ${email}`, { variant: "success" });
        } catch (error) {
            console.log(error);

            setError(error?.message || error);
            setLoading(false);
        }
    }

    const getErrorText = () => {
        switch (error) {
            case null:
                return null;
            case "invitation-exists":
                return "Invitasjon finnes fra før";
            case "admin-exists":
                return "Denne brukeren er allerede en administrator";
            default:
                return "Noe gikk galt";
        }
    }

    const errorText = !!error ? getErrorText() : null;


    return { loading, errorText, handleAdd, setError };
}

export default useAddUser;
