import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { List, ListItem, ListItemText, ListItemAvatar } from '@mui/material';

import { firestore, onSnapshot, collection } from '../../helpers/firebase'

const Stores = () => {
    const [stores, setStores] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(firestore, "stores"), snap => {
            setStores(snap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        }, setError)

        return () => unsubscribe();
    }, [])

    return (
        <div>
            {error && <pre>{JSON.stringify(error, null, 4)}</pre>}

            <List>
                {Array.isArray(stores) && stores.map(store => (
                    <ListItem key={store.id} button component={Link} to={{ pathname: `stores/${store.id}`, state: { data: store } }} >
                        <ListItemAvatar>
                            {store.image && <img alt={store.name} src={store.image} style={{ width: 46, height: 46 }} />}
                        </ListItemAvatar>
                        <ListItemText primary={store.name} secondary={store.description} />
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

export default Stores;
