import { Paper, Box, Typography } from '@mui/material';

const StatValue = ({ stat, loading = false, textAlign }) => {
    if (typeof stat === "undefined") return null;

    if (typeof stat === "object") {
        const { value, positive = true } = stat;

        let valueColor;
        let valuePrefix = "";

        if (typeof value === "number" && value > 0) {
            valueColor = positive ? "#518c5d" : "#f40000";
            valuePrefix = positive ? "+" : "-";
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <Typography textAlign={textAlign} variant='caption' >{`${stat.name}`}</Typography>
                <Typography textAlign={textAlign} variant='body1' color={valueColor} >{loading ? "---" : `${valuePrefix}${value}`}</Typography>
            </Box>
        )
    }

    return (
        <Typography sx={{ width: '100%' }} textAlign={textAlign} variant='h6' >{loading ? "---" : `${stat}`}</Typography>
    )
}

const StatCard = ({ Icon, title, stat1, stat2, loading = false, flexDirection = 'row' }) => {
    return (
        <Paper sx={{ width: 200, height: flexDirection === "column" ? 130 : 100 }} >
            <Box sx={{ p: 1, height: '100%', display: 'flex', alignItems: 'center', flexDirection }} >
                <Icon style={{ fontSize: 40 }} />
                <Box sx={{ marginLeft: flexDirection === "column" ? 0 : 2, display: 'flex', flexDirection: 'column', width: '100%' }} >
                    <Typography variant='body1' textAlign={flexDirection === "column" ? "center" : null} >{title}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: !stat2 ? null : 'space-between' }} >
                        <StatValue stat={stat1} loading={loading} textAlign={flexDirection === "column" ? "center" : null} />
                        <StatValue stat={stat2} loading={loading} textAlign={flexDirection === "column" ? "center" : null} />
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}

export default StatCard;
