import { useState } from "react";
import ReactDOM from 'react-dom';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const confirmDialog = (title, content) => {
    return new Promise(function (resolve, _) {
        addDialog(resolve, title, content);
    });
}

const ConfirmDialog = ({ resolve, title, content }) => {
    const [open, setOpen] = useState(true);

    const handleConfirm = () => {
        setOpen(false);
        removeDialog();
        resolve(true);
    }

    const handleClose = () => {
        setOpen(false);
        removeDialog();
        resolve(false);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {title && (
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                )}

                {content && (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {content}
                        </DialogContentText>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={handleClose}>Nei</Button>
                    <Button onClick={handleConfirm}>Ja</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const addDialog = (resolve, title, content) => {
    const body = document.getElementsByTagName("body")[0];
    const div = document.createElement("div");
    div.setAttribute("id", "confirm-dialog-container");
    body.appendChild(div);
    ReactDOM.render(
        <ConfirmDialog resolve={resolve} title={title} content={content} />,
        div
    );
}

const removeDialog = () => {
    const div = document.getElementById("confirm-dialog-container");
    const body = document.getElementsByTagName("body")[0];
    body.removeChild(div);
}

export default confirmDialog;