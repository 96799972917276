import { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';

import StoreForm from '../../../EditStore/StoreForm';
import confirmDialog from '../../../../helpers/confirmDialog';
import StoreData from './StoreData';

const Profile = ({ data, storeId }) => {
    // TODO: Update data

    const [edit, setEdit] = useState(false);

    const handleCancel = async isChanges => {
        const confirmed = !isChanges || await confirmDialog("Avbryt?", "Er du sikker på at du vil slette alle endringer?");

        if (confirmed) setEdit(false);
    }

    const handleSaved = () => setEdit(false);

    return (
        <div>
            <Typography variant="h3" align="center" sx={{ marginBottom: 4 }} color="primary" >{edit ? "Rediger profil" : "Profil"}</Typography>
            <Typography variant="h4" align="center" sx={{ marginBottom: 5 }} >Informasjon slik den fremstår i appen</Typography>

            {!edit ?
                (
                    <StoreData data={data} />
                ) : (
                    <StoreForm storeId={storeId} data={data} disabled={!edit} onSaved={handleSaved} onCancel={handleCancel} />
                )
            }

            {!edit && (
                <Box sx={{ textAlign: 'center', paddingTop: '16px' }} >
                    <Button variant="outlined" onClick={() => setEdit(true)} >Rediger profil</Button>
                </Box>
            )}
        </div>
    )
}

export default Profile;
