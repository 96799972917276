import { Typography } from '@mui/material';
import Campaigns from './Campaigns';
import Members from './Members';

const Insights = ({ data, storeId }) => {
    return (
        <div>
            <Typography variant="h3" align="center" sx={{ marginBottom: 4 }} color="primary" >Innsikt</Typography>

            <Typography variant="h5" sx={{ marginBottom: 4 }} >Medlemskap</Typography>
            <Members storeId={storeId} />

            <Typography variant="h5" sx={{ marginBottom: 4 }} >Kampanjer</Typography>
            <Campaigns data={data} storeId={storeId} />
        </div>
    )
}

export default Insights;
