import { useState, useEffect, Fragment } from 'react';
// import { List, ListItem, ListItemText } from '@mui/material';
import { Person, StackedLineChart } from '@mui/icons-material';
import { Box, Select, MenuItem } from '@mui/material';

import { functions, httpsCallable } from '../../../../helpers/firebase';
import ProgressIndicator from '../../../../components/ProgressIndicator';
import LineChart from '../../../../components/LineChart';
import { getChartData, getMemberDate } from '../../../../helpers/chartUtils';
import { isToday } from '../../../../helpers/utils';
import StatCard from '../../../../components/StatCard';

const Members = ({ storeId }) => {
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState();
    const [daysToRender, setDaysToRender] = useState(365);

    useEffect(() => {
        const fetchmembers = async () => {
            setLoading(true);

            const call = httpsCallable(functions, "getStoreMembers");

            try {
                const res = await call({ storeId });

                setMembers(res?.data);
            } catch (error) {
                console.log(error);
            }

            setLoading(false);
        }

        fetchmembers();
    }, [storeId])

    const activeMembers = members?.activeMembers;
    const priorMembers = members?.priorMembers;

    const activeMembersData = getChartData(activeMembers, daysToRender);
    const priorMembersData = getChartData(priorMembers, daysToRender);

    const newMembersToday = Array.isArray(activeMembers) ?
        activeMembers.reduce((acc, member) => isToday(getMemberDate(member)) ? acc + 1 : acc, 0) :
        0;
    const lostMembersToday = Array.isArray(priorMembers) ?
        priorMembers.reduce((acc, member) => isToday(getMemberDate(member)) ? acc + 1 : acc, 0) :
        0;

    const series = [
        {
            name: "Nye medlemskap",
            data: activeMembersData,
            color: "#518c5d",
        },
        {
            name: "Avsluttede medlemskap",
            data: priorMembersData,
            color: "#f40000",
        },
    ];

    return (
        <div>
            {loading && <ProgressIndicator />}

            {Array.isArray(activeMembers) && (
                <Fragment>
                    <Box sx={{ marginY: 4, display: 'flex', justifyContent: 'space-between' }} >
                        <StatCard Icon={Person} title="Totalt antall" stat1={activeMembers.length} />
                        <StatCard
                            Icon={StackedLineChart}
                            title="I dag"
                            stat1={{ name: "Nye", value: newMembersToday, positive: true }}
                            stat2={{ name: "Avsluttede", value: lostMembersToday, positive: false }}
                        />
                        <StatCard Icon={Person} title="" />
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }} >
                            <Select sx={{ minWidth: 150 }} value={daysToRender} onChange={e => setDaysToRender(e.target.value)} >
                                <MenuItem value={7}>Siste 7 dager</MenuItem>
                                <MenuItem value={30}>Siste 30 dager</MenuItem>
                                <MenuItem value={365}>Siste år</MenuItem>
                            </Select>
                        </Box>

                        <LineChart series={series} yAxisTitle="Antall medlemskap" />
                    </Box>
                </Fragment>
            )}

        </div>
    )
}

export default Members;
