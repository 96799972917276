import { forwardRef } from 'react'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PhoneInput from 'react-phone-number-input'

import 'react-phone-number-input/style.css'

const useStyles = makeStyles(theme => ({
    input: {
        backgroundColor: '#fff'
    }
}))

const InputComponent = (props, ref) => {
    const classes = useStyles();

    return (
        <TextField
            {...props}
            InputProps={{
                className: classes.input
            }}
            inputRef={ref}
        />
    )
}

const inputComponent = forwardRef(InputComponent);

const PhoneField = ({ ...props }) => (
    <PhoneInput
        {...props}
        international
        defaultCountry="NO"
        inputComponent={inputComponent}
    />
)

export default PhoneField;
