import { Fragment } from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import { Link } from "react-router-dom";

import LoginForm from './forms/LoginForm';

const Login = () => (
    <Fragment>
        <Box sx={{ display: 'flex', justifyContent: 'end', p: 4 }} >
            <Typography>Ikke allerede bruker? <MuiLink component={Link} to="/register" replace >Registrer</MuiLink></Typography>
        </Box>
        <Box sx={{ display: 'flex', height: '90vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
            <LoginForm />
        </Box>
    </Fragment>
);

export default Login;
