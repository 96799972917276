import { SnackbarProvider, useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CloseButton = ({ snackbarKey }) => {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={() => closeSnackbar(snackbarKey)}
        >
            <CloseIcon />
        </IconButton>
    )
}

const MySnackbarProvider = ({ children }) => (
    <SnackbarProvider
        maxSnack={3}
        action={snackbarKey => <CloseButton snackbarKey={snackbarKey} />}
        autoHideDuration={8000}
    >
        {children}
    </SnackbarProvider>
)

export default MySnackbarProvider;
