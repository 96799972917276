import { Box, Typography } from "@mui/material";

const ListRow = ({ number, text }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginBottom: 4 }} >
            <Box
                sx={{
                    borderRadius: 16,
                    width: 67,
                    height: 60,
                    // margin: '47px 19px 26px 28px',
                    marginRight: 2,
                    padding: '14px 26px',
                    backgroundColor: '#518c5d',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h5" textAlign="center" color="white" >{number}</Typography>
            </Box>
            <Typography variant="h5" >{text}</Typography>
        </Box>
    )
};

export default ListRow;
