import { Fragment, useState } from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { Toolbar, Divider, List, ListItem, ListItemIcon, ListItemText, Button, Box, Typography } from '@mui/material';
import { Store, Home, Add, Person, Poll, Settings, ArrowBack, ChevronRight, Campaign, Support, RequestPage, NotificationAdd } from '@mui/icons-material';

import { auth, signOut } from '../../helpers/firebase'
import CampaignModal from "../EditStore/CampaignModal";
import NotificationModal from "../EditStore/NotificationModal";
import Logo from "../../components/Logo";

const routes = [
    {
        text: "Hjem",
        Icon: Home,
        path: "/"
    },
    {
        text: "Butikker",
        Icon: Store,
        path: "/stores"
    },
    {
        text: "Legg til",
        Icon: Add,
        path: "/add-store"
    },
    {
        text: "Brukere",
        Icon: Person,
        path: "/users"
    },
    {
        text: "Søknader",
        Icon: RequestPage,
        path: "/store-requests"
    },
];

const storeRoutes = [
    {
        text: "Profil",
        Icon: Store,
        path: "/profile"
    },
    {
        text: "Innsikt",
        Icon: Poll,
        path: "/insights"
    },
    {
        text: "Kampanjer",
        Icon: Campaign,
        path: "/campaigns"
    },
    /*  {
         text: "Tips & Triks",
         Icon: Lightbulb,
         path: "/tips"
     }, */
    {
        text: "Support",
        Icon: Support,
        path: "/support"
    },
    {
        text: "Innstillinger",
        Icon: Settings,
        path: "/settings"
    },
];

const MyDrawer = ({ close, storeData, isAdmin }) => {
    const [notificationModalOpen, setNotificationModalOpen] = useState(false);
    const [campaignModalOpen, setCampaignModalOpen] = useState(false);
    const location = useLocation();
    const match = useRouteMatch({ path: "/stores/:storeId" });
    const isStorePath = !!match;
    const storePath = isStorePath ? match.url : null;
    const currentRoutes = isStorePath ? storeRoutes : routes;

    const handleSignOut = () => signOut(auth);

    return (
        <div>
            <Toolbar>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 150, width: '100%' }} >
                    <Logo size={140} />

                    <Typography variant="h5" noWrap component="div" textAlign="end" >
                        Admin.
                    </Typography>
                </Box>
            </Toolbar>
            <Divider />

            {storeData && (
                <Fragment>
                    <Box sx={{ p: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        {storeData.image && (
                            <img alt="logo" src={storeData.image} style={{ width: 70, height: 70, marginRight: 16 }} />
                        )}

                        <Typography variant="h6" noWrap component="div" >
                            {storeData.name}
                        </Typography>
                    </Box>

                    <Divider />
                </Fragment>
            )}

            {isAdmin && isStorePath && (
                <Fragment>
                    <ListItem button component={Link} to="/stores" onClick={close} >
                        <ListItemIcon><ArrowBack /></ListItemIcon>
                        <ListItemText primary="Tilbake" />
                    </ListItem>
                    <Divider />
                </Fragment>
            )}

            <List>
                {currentRoutes.map(({ text, Icon, path }) => {
                    const fPath = isStorePath ? `${storePath}${path}` : path;

                    return (
                        <ListItem key={text} button selected={location.pathname === fPath} component={Link} to={fPath} onClick={close} >
                            <ListItemIcon><Icon /></ListItemIcon>
                            <ListItemText primary={text} />
                            <ListItemIcon sx={{ justifyContent: 'end' }} ><ChevronRight /></ListItemIcon>
                        </ListItem>
                    );
                })}
            </List>
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
                {isStorePath && (
                    <Fragment>
                        <Box p={3} display="flex" justifyContent="center" flexDirection="column" gap="16px" >
                            {isAdmin && (
                                <Button
                                    fullWidth
                                    startIcon={<NotificationAdd />}
                                    variant="contained"
                                    onClick={() => setNotificationModalOpen(true)}
                                >
                                    NY VARSLING
                                </Button>
                            )}

                            <Button
                                fullWidth
                                startIcon={<Add />}
                                variant="contained"
                                onClick={() => setCampaignModalOpen(true)}
                            >
                                NY KAMPANJE
                            </Button>
                        </Box>

                        <NotificationModal storeId={match.params.storeId} storeData={storeData} open={notificationModalOpen} handleClose={() => setNotificationModalOpen(false)} />
                        <CampaignModal storeId={match.params.storeId} storeData={storeData} open={campaignModalOpen} handleClose={() => setCampaignModalOpen(false)} />
                    </Fragment>
                )}

                <Button variant="text" color="error" onClick={handleSignOut} >Logg ut</Button>
            </Box>
        </div>
    )
}

export default MyDrawer;
