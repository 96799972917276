import { initializeApp } from 'firebase/app';

import {
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
} from 'firebase/auth';
import {
    getFirestore,
    addDoc,
    collection,
    doc,
    setDoc,
    onSnapshot,
    getDoc,
    deleteDoc,
    updateDoc,
    arrayUnion,
    serverTimestamp,
    arrayRemove,
    getDocs,
    query,
    where,
} from 'firebase/firestore';
import {
    getStorage,
    ref,
    uploadBytes,
    getDownloadURL,
} from "firebase/storage";
import {
    getFunctions,
    connectFunctionsEmulator,
    httpsCallable,
} from "firebase/functions";

const useDev = process.env.REACT_APP_FIREBASE_ENV === "dev" || process.env.NODE_ENV === "development";

let firebaseConfig = {
    apiKey: "AIzaSyA-SRkmPaUCQgICgld527FYWRQsP17SRgk",
    authDomain: "membre-mob.firebaseapp.com",
    projectId: "membre-mob",
    storageBucket: "membre-mob.appspot.com",
    messagingSenderId: "890573055381",
    appId: "1:890573055381:web:601e6104bc650c4b4c429c"
}

if (useDev) {
    firebaseConfig = {
        apiKey: "AIzaSyC0a2czg8kf_RjsThIjFAE3GPqVwRCyfbQ",
        authDomain: "membre-dev.firebaseapp.com",
        projectId: "membre-dev",
        storageBucket: "membre-dev.appspot.com",
        messagingSenderId: "896986493114",
        appId: "1:896986493114:web:ab7be3a5b7e6dde76c5283"
    }
}

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth();
const firestore = getFirestore();
const storage = getStorage();
const functions = getFunctions(firebaseApp, "europe-west2");

if (window.location.hostname === "localhost") connectFunctionsEmulator(functions, "localhost", 5001);

export {
    firebaseApp,

    auth,
    firestore,
    storage,
    functions,

    // auth
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    createUserWithEmailAndPassword,

    // firestore
    addDoc,
    collection,
    doc,
    setDoc,
    onSnapshot,
    getDoc,
    deleteDoc,
    updateDoc,
    arrayUnion,
    serverTimestamp,
    arrayRemove,
    getDocs,
    query,
    where,

    // storage
    ref,
    uploadBytes,
    getDownloadURL,

    // functions
    httpsCallable,
};
