import { useHistory } from "react-router-dom";
import { auth, signOut } from '../../helpers/firebase'

const NoAccess = () => {
    const history = useHistory();

    const handleSignOut = async () => {
        await signOut(auth);
        history.replace("/");
    };

    return (
        <div>
            <h2>Ingen tilgang</h2>
            <button onClick={handleSignOut} >Tilbake</button>
        </div>
    )
}

export default NoAccess;
