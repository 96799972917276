import { useState, Fragment } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { Drawer, Box, Toolbar, AppBar, IconButton, Typography } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

import Stores from "../Stores";
import StoreForm from "../EditStore/StoreForm";
import Users from "../Users";
import MyDrawer from "./MyDrawer";
import Store from "../Store";
import StoreRequests from "../StoreRequests";
import DashboardView from "./DashboardView";

const drawerWidth = 300;

const Dashboard = ({ isAdmin, userStores }) => {
    const { path, url } = useRouteMatch();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [storeData, setStoreData] = useState();
    const redirectStore = isAdmin ? null : userStores?.admin[0];

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box sx={{ display: 'flex' }} >
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    display: { sm: 'none' },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                        size="large">
                        <MenuIcon />
                    </IconButton>
                    <Typography>
                        Membre Admin
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#eaf5ed' },
                    }}
                >
                    <MyDrawer isAdmin={isAdmin} storeData={storeData} close={() => setMobileOpen(false)} />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#eaf5ed' },
                    }}
                    open
                >
                    <MyDrawer isAdmin={isAdmin} storeData={storeData} />
                </Drawer>
            </Box>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />

                <Switch>
                    <Route path={`${url}stores/:storeId`} render={props => <Store {...props} onData={setStoreData} />} />

                    {!isAdmin ? (
                        <Fragment>
                            {redirectStore && <Redirect to={`${url}stores/${redirectStore}`} />}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Route path={path} exact component={DashboardView} />

                            <Route path={`${url}stores/:storeId`} render={props => <Store {...props} onData={setStoreData} />} />

                            <Route path={`${url}add-store`} exact component={StoreForm} />
                            <Route path={`${url}stores`} exact component={Stores} />
                            <Route path={`${url}users`} exact component={Users} />
                            <Route path={`${url}store-requests`} exact component={StoreRequests} />
                        </Fragment>
                    )}
                </Switch>


            </Box>
        </Box>
    );
}

export default Dashboard;
