import { Typography, Box } from '@mui/material';
import AddUser from './AddUser';
import Invitations from './Invitations';
import UserList from './UserList';

const Settings = ({ data, storeId }) => {
    return (
        <div>
            <Typography variant="h3" align="center" sx={{ marginBottom: 4 }} color="primary" >Innstillinger</Typography>

            <Typography variant="h4" >Administratorer</Typography>
            <UserList storeId={storeId} />
            <AddUser storeId={storeId} />

            <Box sx={{ marginBottom: 4 }} />

            <Typography variant="h4" sx={{ marginBottom: 2 }} >Invitasjoner</Typography>
            <Invitations storeId={storeId} />
        </div>
    )
}

export default Settings;
