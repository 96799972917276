import { useState } from 'react';
import { Popover, Button } from '@mui/material';

const PopoverButton = ({ label, children, color }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={{ height: '100%' }} >
            <Button
                fullWidth
                size="large"
                sx={{ height: '100%' }}
                aria-describedby={id}
                variant="outlined"
                onClick={handleClick}
                color={color}
            >
                {label}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {children}
            </Popover>
        </div>
    )
}

export default PopoverButton;
