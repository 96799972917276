import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const options = {
    xAxis: {
        type: 'datetime',
        labels: {
            formatter: function () {
                return Highcharts.dateFormat('%e %b', this.value);
            },
        },
        title: {
            text: 'Tidspunkt'
        }
    },
    tooltip: {
        formatter: function () {
            // TODO: Fix tooltip and x-axis time mismatch
            return `${Highcharts.dateFormat('%e %b %Y', new Date(this.x))}<br/>${this.series.name}: <b>${this.y}</b>`;
        }
    },
    // tooltip: {
    //     headerFormat: '<b>{series.name}</b><br>',
    //     pointFormat: '{point.x:%e. %b}: {point.y:.2f} m'
    // },
}

const LineChart = ({ title, series = [], yAxisTitle }) => {
    Highcharts.setOptions({
        lang: {
            months: ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"],
            shortMonths: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],
            weekdays: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
        }
    })

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    ...options,
                    title: {
                        text: title,
                    },
                    series,
                    yAxis: {
                        title: {
                            text: yAxisTitle,
                        },
                    },
                }}
            />
        </div>
    )
}

export default LineChart;
