import { useState, useEffect, Fragment } from 'react';
import { List, ListItem, ListItemText, Button, Typography, Box, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { firestore, onSnapshot, collection, updateDoc, doc, functions, httpsCallable } from '../../helpers/firebase'
import Modal from '../../components/Modal';

const DataRow = ({ title, value, xs = 6 }) => (
    <Grid item xs={xs} >
        <Box sx={{ display: 'flex', flexDirection: 'column' }} >
            <Typography variant="h6" >{title}</Typography>
            <Typography>{value || "(ikke fylt ut)"}</Typography>
        </Box>
    </Grid>
)

const RequestList = ({ title, list, onClick, defaultExpanded = false }) => (
    <Accordion defaultExpanded={defaultExpanded} >
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography sx={{ width: '33%', flexShrink: 0 }} >{title}</Typography>
            <Typography sx={{ color: 'text.secondary' }} >({list.length})</Typography>
        </AccordionSummary>

        <AccordionDetails>
            {list.length > 0 ?
                (
                    <List>
                        {list.map(request => (
                            <ListItem key={request.id} button onClick={() => onClick(request)} >
                                <ListItemText primary={request.name} secondary={new Date(request.createdAt.seconds * 1000).toLocaleString("nb")} />
                            </ListItem>
                        ))}
                    </List>
                )
                :
                (
                    <Typography sx={{ color: 'text.secondary' }} >Tom</Typography>
                )
            }
        </AccordionDetails>
    </Accordion>
)

const StoreRequests = () => {
    const [requests, setRequests] = useState([]);
    const [error, setError] = useState();
    const [selectedRequest, setSelectedRequest] = useState();

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(firestore, "storeRequests"), snap => {
            setRequests(snap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        }, setError)

        return () => unsubscribe();
    }, [])

    const handleReject = () => {
        updateDoc(doc(firestore, "storeRequests", selectedRequest.id), { rejected: true });

        setSelectedRequest(null);
    }

    const handleAccept = async () => {
        // updateDoc(doc(firestore, "storeRequests", selectedRequest.id), { accepted: true });
        const call = httpsCallable(functions, "acceptStoreRequest");

        try {
            await call({ storeRequestId: selectedRequest.id });
        } catch (error) {
            console.log(error)
        }

        setSelectedRequest(null);
    }

    const newRequests = Array.isArray(requests) ? requests.filter(r => !r.accepted && !r.rejected) : [];
    const acceptedRequests = Array.isArray(requests) ? requests.filter(r => r.accepted) : [];
    const rejectedRequests = Array.isArray(requests) ? requests.filter(r => r.rejected) : [];

    return (
        <div>
            {error && <pre>{JSON.stringify(error, null, 4)}</pre>}

            <RequestList title="Nye" list={newRequests} onClick={setSelectedRequest} defaultExpanded />

            <RequestList title="Godtatt" list={acceptedRequests} onClick={setSelectedRequest} />

            <RequestList title="Avslått" list={rejectedRequests} onClick={setSelectedRequest} />

            {selectedRequest && (
                <Modal
                    id={selectedRequest.id}
                    open={!!selectedRequest}
                    handleClose={() => setSelectedRequest(null)}
                    title={`Søknad fra ${selectedRequest.name}`}
                    actions={
                        <Fragment>
                            {!selectedRequest.accepted && !selectedRequest.rejected && (
                                <Fragment>
                                    <Button color="error" onClick={handleReject} >Avslå</Button>
                                    <Button onClick={handleAccept} >Godta</Button>
                                </Fragment>
                            )}
                        </Fragment>
                    }
                >
                    <Grid container spacing={2} >
                        <DataRow title="Navn på virksomheten" value={selectedRequest.name} xs={12} />
                        <DataRow title="Organisasjonsnummer" value={selectedRequest.orgNr} />
                        <DataRow title="Kategori" value={selectedRequest.category} />
                        <DataRow title="E-postadresse" value={selectedRequest.email} />
                        <DataRow title="Telefonnummer" value={selectedRequest.phone} />
                        <DataRow title="Postadresse" value={selectedRequest.address} />
                        <DataRow title="Postnummer" value={selectedRequest.zip} xs={3} />
                        <DataRow title="Sted" value={selectedRequest.city} xs={3} />
                        <DataRow title="Besøksadresse" value={selectedRequest.address2} />
                        <DataRow title="Postnummer" value={selectedRequest.zip2} xs={3} />
                        <DataRow title="Sted" value={selectedRequest.city2} xs={3} />
                    </Grid>
                </Modal>
            )}

        </div>
    )
}

export default StoreRequests;
