import { Typography, Grid, Box } from '@mui/material';

const RegisterRequestSent = () => {
    return (
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <Grid container spacing={2} >
                <Grid item xs={1} sm={2} />
                <Grid item xs={10} sm={8} >
                    <Typography variant="h3" align="center" sx={{ marginBottom: 4 }} color="primary" >Søknad sendt!</Typography>

                    <Typography variant="h5" align="center" sx={{ marginBottom: 8 }}>
                        Takk for din søknad, og gratulerer med det første steget mot å ta del i fellesskapet!
                        <br />
                        <br />
                        Etter godkjent søknad, vil du få mulighet til å redigere hvordan virksomhetens side vil fremstå
                        på appen, og videre samhandle tettere med dine brukere!
                        <br />
                        <br />
                        Ta gjerne kontakt med oss dersom det skulle være noe.
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <img alt="logo" src="/images/logo.svg" style={{ height: 50 }} />
                    </Box>
                </Grid>
                <Grid item xs={1} sm={2} />
            </Grid>
        </Box>
    )
}

export default RegisterRequestSent;
