import { Fragment, useState } from "react";
import { Button, Box, TextField } from '@mui/material';
import { Add } from '@mui/icons-material';

import Modal from '../../../../components/Modal';
import useAddUser from './hooks/useAddUser';

const AddUser = ({ storeId }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [email, setEmail] = useState("");

    const handleClose = () => {
        setEmail("");
        setError(null);
        setModalOpen(false);
    }

    const { loading, handleAdd, errorText, setError } = useAddUser(storeId, handleClose);

    const handleChange = e => {
        setEmail(e.target.value);
        setError(null);
    }

    const isValid = email.length > 0;

    return (
        <Box sx={{ marginY: 2 }} >
            <Button variant="contained" startIcon={<Add />} onClick={() => setModalOpen(true)} >Legg til administrator</Button>

            <Modal
                id="campaign-preview"
                open={modalOpen}
                handleClose={loading ? null : handleClose}
                maxWidth="xs"
                title="Legg til administrator"
                actions={(
                    <Fragment>
                        <Button disabled={!isValid} onClick={() => handleAdd(email)} >Legg til</Button>
                    </Fragment>
                )}
            >
                <TextField
                    error={!!errorText}
                    fullWidth
                    required
                    disabled={loading}
                    label="E-postadresse"
                    value={email}
                    onChange={handleChange}
                    helperText={errorText}
                />
            </Modal>
        </Box>
    )
}

export default AddUser;
