import { Typography } from '@mui/material';

const Tips = () => {
    return (
        <div>
            <Typography variant="h3" align="center" sx={{ marginBottom: 4 }} color="primary" >Tips & Triks</Typography>
        </div>
    )
}

export default Tips;
