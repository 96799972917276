import { Box, IconButton, Typography, Grid } from "@mui/material"
import { Facebook, Instagram, Copyright } from '@mui/icons-material';

const Footer = () => {
    return (
        <footer>
            <Box sx={{ backgroundColor: '#ccb7ae', paddingX: 10, paddingY: 2 }} >
                <Grid container spacing={2} >
                    <Grid item xs={3}>
                        <IconButton size="large" sx={{ p: 0, color: 'black', marginRight: 2 }} >
                            <Facebook fontSize="inherit" />
                        </IconButton>
                        <IconButton size="large" sx={{ p: 0, color: 'black' }} >
                            <Instagram fontSize="inherit" />
                        </IconButton>
                    </Grid>

                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }} >
                        <Copyright />
                        <Typography>Copyright 2021 membre.no</Typography>
                    </Grid>

                    <Grid item xs={3}>
                        <Typography textAlign="end" >Språk</Typography> {/* TODO */}
                    </Grid>
                </Grid>
            </Box>
        </footer>
    )
}

export default Footer;
