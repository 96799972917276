import { Typography, Box, Paper, Grid } from '@mui/material';
import { Phone, Mail, Facebook } from '@mui/icons-material';

const MyPaper = ({ icon, title, subtitle }) => (
    <Paper sx={{ textAlign: 'center', p: 2, height: 190 }} >
        {icon}
        <Typography>{title}</Typography>
        {subtitle && <Typography sx={{ marginTop: 1, whiteSpace: 'pre' }} >{subtitle}</Typography>}
    </Paper>
)

const Contact = () => {
    return (
        <Box sx={{ maxWidth: 900, margin: '0 auto' }} >
            <Typography variant="h3" align="center" sx={{ marginBottom: 4 }} color="primary" >Kontakt</Typography>

            <Typography variant="h6" align="center" sx={{ marginBottom: 6 }} >
                Ønsker du å vite mer om hvordan tjenesten fungerer, eller er allerede kunde og har noen spørsmål?
                Eller kanskje noe helt annet? Uansett, kontakt oss gjerne!
            </Typography>

            <Grid container spacing={8} marginBottom={8} >
                <Grid item sm={12} md={4} >
                    <MyPaper icon={<Phone />} title="Vi er tilgjengelige på telefon man-fred klokken 08.00-16.00" subtitle={`Ring oss på:\n+47 123 45 678`} />
                </Grid>
                <Grid item sm={12} md={4} >
                    <MyPaper icon={<Mail />} title="Send oss en epost, så skal vi svare så fort som vi får mulighet!" subtitle={`Send til:\nmembre@membre.no`} />
                </Grid>
                <Grid item sm={12} md={4} >
                    <MyPaper icon={<Facebook />} title="Kontakt oss på messenger på facebook, så skal vi svare så fort som vi får mulighet!" />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Contact;
