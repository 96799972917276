import { FormControl, Select, MenuItem, InputLabel, } from '@mui/material';

const categories = [
    "Klær & sko",
    "Interiør & møbler",
    "Hobby & Fritid",
    "Tjenester",
    "Mat & Drikke",
    "Dyr",
    "Sport & Friluftsliv",
    "Barn & Familie",
    "Hus & Hage",
];

const CategorySelect = ({ value, onChange, disabled = false }) => (
    <FormControl fullWidth >
        <InputLabel id="category-label" >Kategori</InputLabel>
        <Select labelId="category-label" label="Kategori" disabled={disabled} value={value} onChange={onChange} >
            {categories.map(cat => (
                <MenuItem key={cat} value={cat}>{cat}</MenuItem>
            ))}
        </Select>
    </FormControl>
);

export default CategorySelect;
