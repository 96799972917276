import { useState, useRef, Fragment } from 'react';
import { TextField, Button, Grid, Box, MenuItem, Divider, Menu, Typography } from '@mui/material';
import { PhotoCamera, Edit } from '@mui/icons-material';
import { LocalizationProvider, TimePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { useSnackbar } from 'notistack';

import moment from "moment";
import "moment/locale/nb";

import { firestore, setDoc, collection, doc, storage, ref, uploadBytes, getDownloadURL } from '../../helpers/firebase'
import { intToDay } from '../../helpers/utils';
import Lightbox from 'react-image-lightbox';
import PopoverButton from '../../components/PopoverButton';
import CategorySelect from '../../components/CategorySelect';
import ZipField from '../../components/ZipField';
import PhoneField from '../../components/PhoneField';

import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

moment.locale("nb");

const stringsToMoment = l => [moment(l[0], moment.HTML5_FMT.TIME), moment(l[1], moment.HTML5_FMT.TIME)];

const momentsToStrings = map => {
    const res = {};

    Object.keys(map).forEach(day => {
        const values = map[day];

        if (Array.isArray(values) && values.length === 2 && !!values[0] && !!values[1]) {
            res[day] = [values[0].format("HH:mm"), values[1].format("HH:mm")];
        } else {
            res[day] = "";
        }
    })

    return res;
}

const StoreForm = ({ storeId, data, disabled = false, onCancel, onSaved }) => {
    const isEdit = !!storeId;

    const dataOpeningHours = data?.openingHours || {};
    const initOpeningHours = {
        1: dataOpeningHours[1] ? stringsToMoment(dataOpeningHours[1]) : [null, null],
        2: dataOpeningHours[2] ? stringsToMoment(dataOpeningHours[2]) : [null, null],
        3: dataOpeningHours[3] ? stringsToMoment(dataOpeningHours[3]) : [null, null],
        4: dataOpeningHours[4] ? stringsToMoment(dataOpeningHours[4]) : [null, null],
        5: dataOpeningHours[5] ? stringsToMoment(dataOpeningHours[5]) : [null, null],
        6: dataOpeningHours[6] ? stringsToMoment(dataOpeningHours[6]) : [null, null],
        7: dataOpeningHours[7] ? stringsToMoment(dataOpeningHours[7]) : [null, null],
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [name, setName] = useState(data?.name || "");
    const [image, setImage] = useState(data?.image);
    const [address, setAddress] = useState(data?.address || "");
    const [zip, setZip] = useState(data?.zip || "");
    const [city, setCity] = useState(data?.city || "");
    const [phone, setPhone] = useState(data?.phone || "");
    const [email, setEmail] = useState(data?.email || "");
    const [facebook, setFacebook] = useState(data?.facebook || "");
    const [instagram, setInstagram] = useState(data?.instagram || "");
    const [web, setWeb] = useState(data?.web || "");
    const [openingHours, setOpeningHours] = useState(initOpeningHours);
    const [description, setDescription] = useState(data?.description || "");
    const [category, setCategory] = useState(data?.category || "");
    const [imgMenuAnchorEl, setImgMenuAnchorEl] = useState(null);
    const [showImage, setShowImage] = useState(false);

    const [countyName, setCountyName] = useState(data?.countyName);
    const [countyNumber, setCountyNumber] = useState(data?.countyNumber);

    const fileRef = useRef();

    const { enqueueSnackbar } = useSnackbar();

    const isChanges = !isEdit ||
        (
            name !== (data.name || "") || image !== data.image ||
            address !== (data.address || "") || zip !== (data.zip || "") ||
            city !== (data.city || undefined) || phone !== (data.phone || "") ||
            email !== (data.email || "") || facebook !== (data.facebook || "") ||
            instagram !== (data.instagram || "") || web !== (data.web || "") ||
            JSON.stringify(openingHours) !== JSON.stringify(initOpeningHours) ||
            description !== (data.description || "") || category !== (data.category || ""))

    const handleSave = async () => {
        setLoading(true);
        setError(null);

        try {
            const docRef = isEdit ? doc(firestore, "stores", storeId) : doc(collection(firestore, "stores"));
            const data = {
                name,
                address,
                zip,
                city: city || null,
                phone,
                email,
                facebook,
                instagram,
                web,
                openingHours: momentsToStrings(openingHours),
                description,
                category,
                countyName: countyName || null,
                countyNumber: countyNumber || null,
            };

            if (!image) {
                data.image = null;
            } else if (typeof image === "object") {
                const storageRef = ref(storage, `logos/${docRef.id}/${image.name}`);
                await uploadBytes(storageRef, image);
                const imageUrl = await getDownloadURL(storageRef);

                data.image = imageUrl;
            }

            await setDoc(docRef, data, { merge: isEdit });

            if (!isEdit) {
                setName("");
                setImage(null);
                fileRef.current.value = "";
            }

            if (onSaved) onSaved();

            enqueueSnackbar("Lagret!", { variant: "success" });
        } catch (err) {
            console.log(err);
            setError(err);
        }

        setLoading(false);
    }

    const openingHoursValid = Object.keys(openingHours).every(key => {
        const o = openingHours[key];
        const isValid = o.every(t => !t || t?._isValid);
        const isCorrect = o[0] && o[1] ? o[0] <= o[1] : true;

        return isValid && isCorrect;
    })

    const isValid = name.length > 0 && openingHoursValid;

    const handleImgClick = (event) => setImgMenuAnchorEl(event.currentTarget);
    const handleImgMenuClose = () => setImgMenuAnchorEl(null);

    const handleViewImage = () => {
        setShowImage(true);
        handleImgMenuClose();
    }

    const handleChangeImage = () => {
        handleImgMenuClose();
        fileRef.current.click();
    }

    const handleRemoveImage = () => {
        handleImgMenuClose();
        setImage(null);
    }

    const handleAddress = address => {
        setCity(address?.poststed);
        setCountyName(address?.kommunenavn);
        setCountyNumber(address?.kommunenummer);
    }

    return (
        <Fragment>
            <Grid container spacing={2} >
                <Grid item container justifyContent="center" >
                    {image ? (
                        <Fragment>
                            <Box sx={{ width: 120, height: 120, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }} >
                                <img
                                    onClick={disabled ? null : handleImgClick}
                                    alt="logo"
                                    src={typeof image === "object" ? URL.createObjectURL(image) : image}
                                    style={{ width: 100, height: 100, cursor: disabled ? null : 'pointer' }}
                                />

                                {!disabled && (
                                    <Box
                                        onClick={handleImgClick}
                                        sx={{
                                            cursor: 'pointer',
                                            position: 'absolute',
                                            bottom: 0,
                                            right: 0,
                                            borderRadius: '50%',
                                            backgroundColor: '#518c5d',
                                            width: '2em',
                                            height: '2em',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Edit />
                                    </Box>
                                )}
                            </Box>

                            <Menu
                                id="img-menu"
                                anchorEl={imgMenuAnchorEl}
                                open={Boolean(imgMenuAnchorEl)}
                                onClose={handleImgMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <MenuItem onClick={handleViewImage}>Se bilde</MenuItem>
                                <MenuItem onClick={handleChangeImage}>Endre bilde</MenuItem>
                                <MenuItem onClick={handleRemoveImage}>Fjern bilde</MenuItem>
                            </Menu>
                        </Fragment>
                    ) : (
                        <Button startIcon={<PhotoCamera />} variant="contained" onClick={() => fileRef.current.click()} >Last opp bilde</Button>
                    )}

                    <input style={{ display: 'none' }} accept="image/*" disabled={loading} ref={fileRef} type="file" onChange={e => setImage(e.target.files[0])} />
                </Grid>

                <Grid item xs={12} >
                    <TextField fullWidth label="Navn på virksomheten" disabled={loading || disabled} value={name} onChange={e => setName(e.target.value)} />
                </Grid>

                <Grid item xs={6} >
                    <TextField fullWidth label="E-postadresse" disabled={loading || disabled} value={email} onChange={e => setEmail(e.target.value)} />
                </Grid>

                <Grid item xs={6} >
                    <PhoneField fullWidth label="Telefonnummer" disabled={loading || disabled} value={phone} onChange={value => setPhone(value)} />
                </Grid>

                <Grid item xs={6} >
                    <TextField fullWidth label="Besøksadresse" disabled={loading || disabled} value={address} onChange={e => setAddress(e.target.value)} />
                </Grid>

                <Grid item xs={3} >
                    <ZipField fullWidth label="Postnummer" disabled={loading || disabled} value={zip} onChange={e => setZip(e.target.value)} onAddress={handleAddress} />
                </Grid>

                <Grid item xs={3} >
                    <TextField fullWidth inputProps={{ readOnly: true }} label="Sted" disabled value={city || ""} />
                </Grid>

                <Grid item xs={6} >
                    <CategorySelect value={category} disabled={loading || disabled} onChange={e => setCategory(e.target.value)} />
                </Grid>

                <Grid item xs={6} >
                    <PopoverButton label="Åpningstider" color={!openingHoursValid ? "error" : "primary"} >
                        <Box sx={{ p: 2 }} width={400} >
                            {Object.keys(openingHours).map((day, index) => (
                                <div key={day} >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                        <label>{intToDay(day)}</label>
                                        <Box sx={{ display: 'flex', alignItems: 'center', width: 280 }}>
                                            <LocalizationProvider dateAdapter={DateAdapter} locale="no" >
                                                <TimePicker
                                                    value={openingHours[day][0]}
                                                    onChange={value => setOpeningHours(oldState => ({ ...oldState, [day]: [value, oldState[day][1]] }))}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    maxTime={openingHours[day][1]}
                                                    disabled={loading || disabled}
                                                />
                                                <Typography sx={{ marginX: 2 }} >-</Typography>
                                                <TimePicker
                                                    value={openingHours[day][1]}
                                                    onChange={value => setOpeningHours(oldState => ({ ...oldState, [day]: [oldState[day][0], value] }))}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    minTime={openingHours[day][0]}
                                                    disabled={loading || disabled}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    </Box>
                                    {index < Object.keys(openingHours).length - 1 && <Divider sx={{ marginY: 1 }} />}
                                </div>
                            ))}
                        </Box>
                    </PopoverButton>
                </Grid>

                <Grid item xs={12} >
                    <TextField fullWidth multiline minRows="3" label="Om virksomheten" disabled={loading || disabled} value={description} onChange={e => setDescription(e.target.value)} />
                </Grid>

                <Grid item xs={4} >
                    <TextField fullWidth label="Facebook" disabled={loading || disabled} value={facebook} onChange={e => setFacebook(e.target.value)} />
                </Grid>

                <Grid item xs={4} >
                    <TextField fullWidth label="Instagram" disabled={loading || disabled} value={instagram} onChange={e => setInstagram(e.target.value)} />
                </Grid>

                <Grid item xs={4} >
                    <TextField fullWidth label="Web" disabled={loading || disabled} value={web} onChange={e => setWeb(e.target.value)} />
                </Grid>

                {!disabled && (
                    <Grid item container justifyContent="end" >
                        {onCancel && (
                            <Button sx={{ marginRight: 4 }} variant="outlined" disabled={loading} onClick={() => onCancel(isChanges)} >Avbryt</Button>
                        )}
                        <Button variant="contained" disabled={loading || !isValid || !isChanges} onClick={handleSave} >Lagre</Button>
                    </Grid>
                )}

                {error && <pre>{JSON.stringify(error, null, 4)}</pre>}
            </Grid>

            {showImage && (
                <Lightbox
                    mainSrc={typeof image === "object" ? URL.createObjectURL(image) : image}
                    onCloseRequest={() => setShowImage(false)}
                    reactModalStyle={{ overlay: { zIndex: 10000 } }}
                />
            )}
        </Fragment>
    )
}

export default StoreForm;
