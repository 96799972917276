import { useEffect } from 'react';
import { TextField } from '@mui/material';
import axios from 'axios';

const ZipField = ({ value, onAddress, ...props }) => {
    useEffect(() => {
        const fetchCity = async (zip) => {
            const url = `https://ws.geonorge.no/adresser/v1/sok?side=0&asciiKompatibel=true&utkoordsys=4258&treffPerSide=1&postnummer=${zip}`;
            let address;

            try {
                const res = await axios.get(url);
                const addresses = res?.data?.adresser;

                if (Array.isArray(addresses) && addresses.length > 0) {
                    address = addresses[0];
                } else {
                    address = null;
                }

            } catch (error) {
                address = null;
            }

            if (zip === value) onAddress(address);
        }

        if (value.length === 4) {
            fetchCity(value)
        } else {
            onAddress(null);
        }
    }, [value, onAddress])

    return (
        <TextField {...props} value={value} inputProps={{ maxLength: 4 }} />
    )
}

export default ZipField;
