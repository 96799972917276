import { useState } from 'react';
import { Button, TextField, Box, Typography, Card, Alert } from '@mui/material';

import { auth, createUserWithEmailAndPassword } from '../../../helpers/firebase'

const RegisterForm = ({ onAuth }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const isError = !!error;

    const passwordsEqual = password === repeatPassword;
    const passwordValid = password.length >= 8;
    const isValid = passwordsEqual && !!email && passwordValid;
    const passwordError = passwordValid && !passwordsEqual;
    const emailError = error?.code === "auth/email-already-in-use" || error?.code === "auth/invalid-email"

    const getErrorText = () => {
        switch (error?.code) {
            case "auth/email-already-in-use":
                return "Det finnes en bruker med denne e-postadressen";
            case "auth/invalid-email":
                return "Ugyldig e-postadresse";
            case "auth/weak-password":
                return "Passord er for svakt";
            default:
                return "Noe gikk galt";
        }
    }

    const handleRegister = async () => {
        setLoading(true);
        setError(null);

        try {
            const res = await createUserWithEmailAndPassword(auth, email, password);

            if (onAuth) onAuth(res.user);
        } catch (err) {
            console.log(err.code)
            setError(err);
        }

        setLoading(false);
    }

    const handleSubmit = e => {
        e?.preventDefault();

        handleRegister();
    }

    return (
        <Card sx={{ p: 2, backgroundColor: '#eaf5ed', maxWidth: 500, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
            <Box sx={{ p: 2 }} >
                <img alt="logo" src="/images/logo.svg" style={{ height: 50 }} />
            </Box>

            <Typography variant="h3" align="center" >Registrer bruker</Typography>

            <Box sx={{ p: 2 }} >
                <form onSubmit={handleSubmit} >
                    <TextField
                        error={emailError}
                        sx={{ marginY: 2 }}
                        fullWidth
                        label="E-postadresse"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        helperText={null}
                        onFocus={() => setError(null)}
                    />

                    <TextField
                        error={passwordError}
                        sx={{ marginY: 2 }}
                        fullWidth
                        label="Passord"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        helperText={"Må inneholde minst 8 tegn"}
                        onFocus={() => setError(null)}
                    />

                    <TextField
                        error={passwordError}
                        sx={{ marginY: 2 }}
                        fullWidth
                        label="Gjenta passord"
                        type="password"
                        value={repeatPassword}
                        onChange={e => setRepeatPassword(e.target.value)}
                        helperText={passwordValid && !passwordsEqual ? "Passord er ikke like" : null}
                        onFocus={() => setError(null)}
                    />

                    <Button sx={{ marginTop: 2 }} fullWidth type="submit" variant="contained" disabled={loading || !isValid} >REGISTRER DEG</Button>
                </form>
            </Box>

            {isError && (
                <Alert severity="error" >{getErrorText()}</Alert>
            )}
        </Card>
    )
}

export default RegisterForm;
