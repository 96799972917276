import { useState, useEffect } from 'react';
import { Typography, Paper, Grid } from "@mui/material";

import { firestore, collection, getDocs } from '../../helpers/firebase';

const StatCard = ({ title, value }) => (
    <Grid item xs={2} >
        <Paper sx={{ p: 1 }} >
            <Typography variant="h6" >{title}</Typography>
            <Typography>{value || '---'}</Typography>
        </Paper>
    </Grid>
)

const DashboardView = () => {
    const [stores, setStores] = useState();

    useEffect(() => {
        const fetchStores = async () => {
            const snap = await getDocs(collection(firestore, "stores"));

            setStores(snap.docs);
        }

        fetchStores();
    }, [])

    return (
        <div>
            <Typography variant="h2" component="div" marginBottom={4} >Dashbord for administrator</Typography>

            <Grid container spacing={2} >
                <StatCard title="Antall butikker" value={stores?.length} />
                <StatCard title="Antall brukere" />
                <StatCard title="Antall kampanjer" />
            </Grid>
        </div>
    )
}

export default DashboardView;
