import { Typography, Link } from '@mui/material';

const Support = () => {
    return (
        <div>
            <Typography variant="h3" align="center" sx={{ marginBottom: 4 }} color="primary" >Support</Typography>
            <Typography variant="h5" align="center" sx={{ marginTop: 8 }} >
                Har du problemer, eller bare lurer på noe?
                <br />Kontakt support på:
                <br /><Link href="mailto:membre.adm@support.no" >membre.adm@support.no</Link>
            </Typography>
        </div>
    )
}

export default Support;
