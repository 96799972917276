import { useState } from 'react';
import { Button, TextField, Box, Typography, Card, Alert } from '@mui/material';

import { auth, signInWithEmailAndPassword } from '../../../helpers/firebase'

const LoginForm = ({ onAuth }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async () => {
        setLoading(true);
        setError(null);

        try {
            const res = await signInWithEmailAndPassword(auth, email, password);

            if (onAuth) onAuth(res.user);
        } catch (err) {
            console.log(err.code)
            setError(err);
        }

        setLoading(false);
    }

    const handleSubmit = e => {
        e?.preventDefault();

        handleLogin();
    }

    const isError = !!error;
    const isWrongEmailPassword = isError && typeof error === "object" && (error.code === "auth/user-not-found" || error.code === "auth/wrong-password" || error.code === "auth/invalid-email");

    return (
        <Card sx={{ p: 2, backgroundColor: '#eaf5ed', maxWidth: 500, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
            <Box sx={{ p: 2 }} >
                <img alt="logo" src="/images/logo.svg" style={{ height: 50 }} />
            </Box>

            <Typography variant="h3" align="center" >Velkommen tilbake!</Typography>

            <Box sx={{ p: 2 }} >
                <form onSubmit={handleSubmit} >
                    <TextField
                        error={isError}
                        sx={{ marginY: 2 }}
                        fullWidth
                        label="E-postadresse"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        helperText={isWrongEmailPassword ? "Feil e-postadresse eller passord" : null}
                        onFocus={() => setError(null)}
                    />

                    <TextField
                        error={isError}
                        sx={{ marginY: 2 }}
                        fullWidth
                        label="Passord"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        helperText={isWrongEmailPassword ? "Feil e-postadresse eller passord" : null}
                        onFocus={() => setError(null)}
                    />

                    <Button sx={{ marginTop: 2 }} fullWidth type="submit" variant="contained" disabled={loading} >Logg inn</Button>
                </form>
            </Box>

            {isError && !isWrongEmailPassword && (
                <Alert severity="error" >Noe gikk galt</Alert>
            )}
        </Card>
    )
}

export default LoginForm;
