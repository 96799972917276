import { Typography } from '@mui/material';

import ImageTextRow from '../../../../components/ImageTextRow';

const HowTo = () => {
    return (
        <div>
            <Typography variant="h3" align="center" sx={{ marginBottom: 4 }} color="primary" >5 kjappe spørsmål og svar</Typography>

            <ImageTextRow
                title="1. Hvorfor er det bedre å annonsere gjennom membre enn via e-post- eller sms-tjenester?"
                text="Membre gir unik tilgang til en plattform som er brukervennlig og innbydende for kundene dine, og vi tør å påstå at bedrifter vil bli etterspurt dersom brukere ikke finner den her."
                imgSrc="/images/how-to/community.svg"
                imgAlt="community"
            />

            <ImageTextRow
                isImgLeft
                title="2. Hvor mye koster det?"
                text="Å komme i gang og å få tilgang til basisfunksjoner er helt gratis, men dersom dere ønsker å utvide funksjonaliteten kan dere  kontakte oss for en uforpliktende samtale om muligheter og pris!"
                imgSrc="/images/how-to/betaling-2.svg"
                imgAlt="betaling-2"
            />

            <ImageTextRow
                title="3. Hvor enkelt er det å lage og publisere kampanjer selv?"
                text="Membre-plattformen er utformet på en måte som skal gjøre det enklest mulig for alle typer brukere å både lage og  publisere kampanjer, sammen med de andre funksjonene som plattformen tilbyr."
                imgSrc="/images/how-to/design(1).svg"
                imgAlt="design"
            />

            <ImageTextRow
                isImgLeft
                title="4. Får man tilgang på innsikt?"
                text="Bedriften får tilgang til en funksjon som viser til ulik innsikt, som eksempelvis hvor mange som bruker kampanjene og tidspunkt de blir oftest brukt."
                imgSrc="/images/how-to/innsikt-2(1).svg"
                imgAlt="innsikt-2"
            />

            <ImageTextRow
                title="5. Hvor mye må bedriften gjøre for å ta i bruk membre?"
                text="Bedriften må ikke gjøre annet enn å sende inn søknad og å skape innhold og kampanjer!"
                imgSrc="/images/how-to/relax.svg"
                imgAlt="relax"
            />

            <ImageTextRow
                isImgLeft
                title="Fikk du ikke svar på det du lurte på? Kontakt oss for en uforpliktende samtale!"
                imgSrc="/images/how-to/sp-rsma-l.svg"
                imgAlt="sp-rsma-l"
            />

            {/* <Typography variant="h6" align="center" sx={{ marginBottom: 6 }} >
                Nysgjerrig på hva membre kan gjøre for din virksomhet, og hvordan det fungerer?<br />
                Ta en titt på denne videoen!
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                <Box sx={{ width: 600 }} >
                    <YoutubeEmbed embedId="rokGy0huYEA" />
                </Box>
            </Box> */}
        </div>
    )
}

export default HowTo;
