import { Fragment } from "react";
import { Typography, Button, Grid, Stack, Box, Paper, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import { ArrowForward } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

import ListRow from "./ListRow";

const Homepage = () => {
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

    return (
        <Fragment>
            <Grid container spacing={2} sx={{ p: 2 }} >
                <Grid item xs={12} md={5} >
                    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }} >
                        <Box>
                            <Typography variant="h3" component="div" >Registrer din virksomhet på membre, og ta del i fellesskapet!</Typography>
                            <Box sx={{ marginTop: 4, marginLeft: 8 }} >
                                <Button endIcon={<ArrowForward />} variant="contained" component={Link} to="/register" >Registrer nå!</Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} >
                    <Stack textAlign={isMd ? null : "center"} >
                        <Box sx={{ maxHeight: 200 }} >
                            <img alt="details" src="/images/homepage/details.svg" style={{ height: '100%' }} />
                        </Box>
                        <Box sx={{ maxHeight: 200, marginLeft: isMd ? 40 : 0, marginTop: isMd ? -14 : 0 }} >
                            <img alt="undraw-winners" src="/images/homepage/undraw-winners.svg" style={{ height: '100%' }} />
                        </Box>
                        <Box sx={{ maxHeight: 200, marginLeft: isMd ? 20 : 0 }} >
                            <img alt="social" src="/images/homepage/social.svg" style={{ height: '100%' }} />
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
            <Box sx={{ backgroundColor: '#e0f2e9', p: 2 }} >
                <Grid container spacing={2} justifyContent="space-evenly" alignItems="end" >
                    <Grid item xs={12} md={4} sx={{ textAlign: 'center' }} >
                        <img alt="kunde" src="/images/homepage/kunde.svg" style={{ height: '100%' }} />
                        <Typography variant="h4" >Annonsér til kundene via membre</Typography>
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ textAlign: 'center' }} >
                        <img alt="design" src="/images/homepage/design.svg" style={{ height: '100%' }} />
                        <Typography variant="h4" >Skap egne annonser og innhold</Typography>
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ textAlign: 'center' }} >
                        <img alt="innsikt-2" src="/images/homepage/innsikt-2.svg" style={{ height: '100%' }} />
                        <Typography variant="h4" >Få oversikt over verdifull innsikt</Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ margin: '100px auto', maxWidth: 900 }} >
                <Paper sx={{ backgroundColor: '#e9ddd8', p: 4 }} >
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={5} sx={{ textAlign: 'center' }} >
                            <img alt="design" src="/images/homepage/appScreenshot.png" style={{ height: '100%' }} />
                        </Grid>
                        <Grid item xs={12} md={7} >
                            <Typography sx={{ marginBottom: 5 }} variant="h4" textAlign="center" >Kom enkelt i gang:</Typography>

                            <ListRow
                                number="1"
                                text={<Fragment>Fyll ut søknadsskjemaet <MuiLink component={Link} to="/register" >her</MuiLink></Fragment>}
                            />
                            <ListRow number="2" text="Vent på at vi behandler søknaden" />
                            <ListRow number="3" text="Tilgang mottas på e-postadressen som er oppgitt på søknadsskjemaet" />
                            <ListRow number="4" text="Start å skap innhold og annonsér direkte til kundene ved å logge inn" />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>

            {/* <Box sx={{ height: 100 }} /> */}
        </Fragment>
    );
};

export default Homepage;
