import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
// import green from '@mui/material/colors/green';

import AuthRoute from "./helpers/AuthRoute";
import PrivateRoute from './helpers/PrivateRoute';
import Login from './views/Auth/Login';
import NoAccess from "./views/Auth/NoAccess";
import Dashboard from './views/Dashboard';
import Home from './views/Home';
import Register from "./views/Auth/Register";
import RegisterStoreRequest from "./views/Auth/RegisterStoreRequest";
import MySnackbarProvider from "./components/MySnackbarProvider";

const App = () => {
  const theme = createTheme(adaptV4Theme({
    palette: {
      primary: {
        main: '#518c5d',
        contrastText: "#ffffff",
      },
      // mode: 'dark',
    },
    // components: {
    //   MuiTextField: {
    //     defaultProps: {
    //       sx: {
    //         m: 2,
    //       }
    //     }
    //   },
    //   MuiButton: {
    //     defaultProps: {
    //       sx: {
    //         m: 2,
    //       }
    //     }
    //   }
    // }
  }));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme} >
        <CssBaseline />
        <MySnackbarProvider>
          <Router>
            <div className="App">
              <Switch>
                <Route path="/no-access" exact component={NoAccess} />
                <Route path="/register-store-request" exact component={RegisterStoreRequest} />
                <Route path="/register-store-admin" exact component={RegisterStoreRequest} />

                <AuthRoute path="/login" exact component={Login} />
                <AuthRoute path="/register" exact component={Register} />

                <PrivateRoute path="/" Component={Dashboard} publicComponent={Home} />

                {/* TODO: Not found */}
              </Switch>
            </div>
          </Router>
        </MySnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
