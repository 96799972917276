import { Typography, Grid, Box } from '@mui/material';
import { intToDay } from '../../../../helpers/utils';

const Field = ({ label, value, sm = 6 }) => (
    <Grid item xs={12} sm={sm} >
        <Typography variant="h5" color="primary" >{label}</Typography>
        <Typography>{typeof value === "string" && value.length > 0 ? value : "-"}</Typography>
    </Grid>
)

const StoreData = ({ data }) => {
    const { image, name, email, phone, address, zip, city, category, openingHours, description, facebook, instagram, web } = data;

    const openingHourToString = day => {
        const value = openingHours[day];

        if (!Array.isArray(value)) return `${intToDay(day)}: stengt`;

        return `${intToDay(day)}: ${value.join(" - ")}`;
    }

    const openingHoursString = typeof openingHours === "object" ? Object.keys(openingHours).map(openingHourToString).join(", ") : "";

    return (
        <Grid container spacing={2} >
            <Grid item container justifyContent="center" >
                {image && (
                    <Box sx={{ width: 120, height: 120, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <img
                            alt="logo"
                            src={typeof image === "object" ? URL.createObjectURL(image) : image}
                            style={{ width: 100, height: 100 }}
                        />
                    </Box>
                )}
            </Grid>

            <Field label="Navn på virksomheten" value={name} sm={12} />

            <Field label="E-postadresse" value={email} />

            <Field label="Telefonnummer" value={phone} />

            <Field label="Besøksadresse" value={address} />

            <Field label="Postnummer" value={zip} sm={3} />

            <Field label="Sted" disabled value={city} sm={3} />

            <Field label="Kategori" value={category} />

            <Field label="Åpningstider" value={openingHoursString} />

            <Field label="Om virksomheten" value={description} sm={12} />

            <Field label="Facebook" value={facebook} sm={4} />

            <Field label="Instagram" value={instagram} sm={4} />

            <Field label="Web" value={web} sm={4} />
        </Grid>
    )
}

export default StoreData;
