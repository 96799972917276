import { Fragment, useState } from 'react';
import { ButtonBase, Box, Typography } from '@mui/material';

import CampaignPreview from '../../components/campaign/CampaignPreview';
import Modal from '../../components/Modal';
import confirmDialog from '../../helpers/confirmDialog';
import { filterCampaigns } from '../../helpers/utils';
import { functions, httpsCallable } from '../../helpers/firebase';

const NotificationModal = ({ storeId, storeData, open, handleClose }) => {
    const [loading, setLoading] = useState(false);

    const { publishedCampaigns } = filterCampaigns(storeData?.campaigns);

    const handleClick = async id => {
        const confirmed = await confirmDialog("Er du sikker?", "Er du sikker på at du vile sende en varsling på denne kampanjen?");

        if (confirmed) {
            setLoading(true);

            const call = httpsCallable(functions, "sendCampaignMessage");

            try {
                await call({ storeId, campaignId: id });
            } catch (error) {
                console.log(error);
            }

            setLoading(false);
        }
    }

    return (
        <Modal open={open} handleClose={handleClose} title="Ny Varsling" >
            {publishedCampaigns.length <= 0 ? (
                <Typography variant="h5" align="center" >Ingen publiserte kampanjer</Typography>
            ) : (
                <Fragment>
                    <Typography variant="h4" sx={{ marginBottom: 4 }} >Velg kampanje</Typography>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }} >
                            {publishedCampaigns.map(campaign => (
                                <ButtonBase
                                    key={`${campaign.text}-${campaign.startDate}-${campaign.endDate}`}
                                    sx={{ textAlign: 'start' }}
                                    onClick={() => handleClick(campaign.id)}
                                    disabled={loading}
                                >
                                    <CampaignPreview
                                        campaign={campaign}
                                        store={storeData}
                                    />
                                </ButtonBase>
                            ))}
                        </Box>
                </Fragment>
            )
            }


        </Modal>
    )
}

export default NotificationModal;
