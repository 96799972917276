import { Box, Button, Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Fragment } from 'react';

import CampaignPreview from "../../../../components/campaign/CampaignPreview";

const CampaignGroup = ({ title, store, campaigns, onEdit, onDelete, onUnpublish, defaultExpanded = false }) => {
    return (
        <Accordion defaultExpanded={defaultExpanded} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }} >{title}</Typography>
                <Typography sx={{ color: 'text.secondary' }} >({campaigns.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {!Array.isArray(campaigns) || campaigns.length <= 0 ? (
                    <Typography sx={{ color: 'text.secondary' }} >Ingen</Typography>
                ) : (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }} >
                        {campaigns.map(campaign => (
                            <CampaignPreview
                                key={`${campaign.text}-${campaign.startDate}-${campaign.endDate}`}
                                campaign={campaign}
                                store={store}
                                actions={(
                                    <Fragment>
                                        <Button size="small" onClick={() => onEdit(campaign.index)} >Rediger</Button>
                                        <Button size="small" color="error" onClick={() => onDelete(campaign.index)} >Slett</Button>
                                        {onUnpublish && <Button size="small" color="warning" onClick={() => onUnpublish(campaign.index)} >Upubliser</Button>}
                                    </Fragment>
                                )}
                            />
                        ))}
                    </Box>
                )}

            </AccordionDetails>
        </Accordion>
    )
}

export default CampaignGroup;
