export const intToDay = i => {
    switch (`${i}`) {
        case "1":
            return "Mandag";
        case "2":
            return "Tirsdag";
        case "3":
            return "Onsdag";
        case "4":
            return "Torsdag";
        case "5":
            return "Fredag";
        case "6":
            return "Lørdag";
        case "7":
            return "Søndag";
        default:
            return `${i}`;
    }
}

export const isNormalInteger = (str) => {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
}

export const filterCampaigns = campaigns => {
    const campaignsWithIndex = Array.isArray(campaigns) ? campaigns.map((c, i) => ({ ...c, index: i })) : [];

    const publishedCampaigns = campaignsWithIndex
        .filter(c => !!c.published && (new Date(c.startDate.seconds * 1000) < new Date() && new Date(c.endDate.seconds * 1000) > new Date()));
    const comingCampaigns = campaignsWithIndex
        .filter(c => !!c.published && (new Date(c.startDate.seconds * 1000) > new Date() && new Date(c.endDate.seconds * 1000) > new Date()));
    const expiredCampaigns = campaignsWithIndex
        .filter(c => !!c.published && (new Date(c.startDate.seconds * 1000) < new Date() && new Date(c.endDate.seconds * 1000) < new Date()));

    const draftCampaigns = campaignsWithIndex.filter(c => !c.published);

    return { publishedCampaigns, comingCampaigns, expiredCampaigns, draftCampaigns };
}

export const isToday = date => {
    if (!date) return false;

    const now = new Date();

    return now.getDate() === date.getDate() &&
        now.getMonth() === date.getMonth() &&
        now.getFullYear() === date.getFullYear();
}