import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    size="large">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const Modal = ({ id, open, handleClose, children, title, actions, maxWidth = "md" }) => {
    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby={id}
            open={open}
            maxWidth={maxWidth}
            fullWidth
        >
            {title && (
                <BootstrapDialogTitle id={id} onClose={handleClose}>
                    {title}
                </BootstrapDialogTitle>
            )}

            <DialogContent dividers>
                {children}
            </DialogContent>

            {actions && (
                <DialogActions>
                    {actions}
                </DialogActions>
            )}
        </BootstrapDialog>
    )
}

export default Modal;
