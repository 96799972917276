import { useState, useEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material';

import RegisterForm from './forms/RegisterForm';
import LoginForm from './forms/LoginForm';

import { auth, onAuthStateChanged, functions, httpsCallable } from '../../helpers/firebase'
import ProgressIndicator from '../../components/ProgressIndicator';

const RegisterStoreRequest = () => {
    const [initUser, setInitUser] = useState(null);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [redirectTo, setRedirectTo] = useState();

    const location = useLocation();
    
    const isAdminRegister = location.pathname === "/register-store-admin";

    const queryParams = new URLSearchParams(location.search)
    const clue = queryParams.get("clue");
    // TODO: Fetch store data by clue

    const handleRegister = async (user) => {
        if (clue && user) {
            setLoading(true);

            const call = httpsCallable(functions, isAdminRegister ? "registerStoreAdmin" : "registerStoreRequest");

            try {
                const res = await call({ clue, uid: user.uid });

                setRedirectTo(`/stores/${res.data.storeId}`);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setInitUser(user);
            unsubscribe();
        });

        return () => unsubscribe();
    }, []);

    if (redirectTo) return <Redirect to={redirectTo} />

    if (!clue) return <Redirect to="/" />;

    if (loading) return <ProgressIndicator />;

    if (error) return ( // TODO
        <Button onClick={() => setError(null)} >Prøv på nytt</Button>
    );

    if (initUser) return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, marginTop: 8 }} >
            <Typography textAlign="center" >
                Du er logget inn med email {initUser.email}, vil du bruke denne eller logge deg på med en annen bruker?
            </Typography>

            <Button variant="contained" onClick={() => handleRegister(initUser)} >Bruk {initUser.email}</Button>
            <Button variant="contained" onClick={() => setInitUser(null)} >Bruk en annen bruker</Button>
        </Box>
    )

    return (
        <div>
            <Box sx={{ display: 'flex', height: '90vh', alignItems: 'center', justifyContent: 'space-evenly', flexWrap: 'wrap', gap: 2 }} >
                <RegisterForm onAuth={handleRegister} />

                <LoginForm onAuth={handleRegister} />
            </Box>
        </div>
    );
}

export default RegisterStoreRequest;
