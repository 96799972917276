import { Typography } from '@mui/material';

const Logo = ({ size = 150 }) => {
    const border = "solid 2px #518c5d";

    return (
        <div style={{ width: size, height: size / 1.8 }} >
            <div style={{ position: "relative" }} >
                <div style={{ position: 'absolute', width: 1, height: size / 2, border }} />
                <div style={{ position: 'absolute', width: size, height: 1, border }} />
                <div style={{ position: 'absolute', top: 0, right: 0, width: 1, height: size / 2, border }} />
                <div style={{ position: 'absolute', top: size / 2, left: 0, width: size / 4, height: 1, border }} />
                <div style={{ position: 'absolute', top: size / 2, right: 0, width: size / 4, height: 1, border }} />
                <div style={{ position: 'absolute', top: size / 6, width: "100%", textAlign: 'center' }} >
                    <Typography noWrap style={{ fontSize: size / 8, fontWeight: 600 }} >Membre</Typography>
                </div>
                <img alt="logo" src="/images/logo.svg" style={{ width: size / 3, position: 'absolute', top: size / 2.4, left: size / 3 }} />
            </div>

        </div>
    )
}

export default Logo;
