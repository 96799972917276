import { useState, useEffect } from 'react';
import { Person, CheckCircle } from '@mui/icons-material';
import { Box, Select, MenuItem } from '@mui/material';

import StatCard from '../../../../components/StatCard';
import { functions, httpsCallable } from '../../../../helpers/firebase';
import LineChart from '../../../../components/LineChart';
import { getChartData } from '../../../../helpers/chartUtils';

const CampaignInsights = ({ storeId, campaign, expanded }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [hasFetched, setHasFetched] = useState(false);
    const [daysToRender, setDaysToRender] = useState(365);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);

            const call = httpsCallable(functions, "getCampaignUsage");

            try {
                const res = await call({ storeId, campaignId: campaign.id });

                setData(res?.data);
            } catch (error) {
                console.log(error);
            }

            setHasFetched(true);
            setLoading(false);
        }

        if (expanded && !loading && !hasFetched) {
            fetch();
        }
    }, [expanded, loading, hasFetched, storeId, campaign])

    const usageChartData = getChartData(data?.usage, daysToRender);

    const series = [
        {
            name: "Antall ganger brukt",
            data: usageChartData,
            color: "#518c5d",
        },
    ];

    return (
        <Box>
            <Box sx={{ marginY: 4, display: 'flex', justifyContent: 'space-between' }} >
                <StatCard loading={loading} flexDirection="column" Icon={CheckCircle} title="Totalt antall ganger brukt" stat1={data?.usage?.length || 0} />
                <StatCard loading={loading} flexDirection="column" Icon={Person} title="Totalt antall medlemmer brukt" stat1={data?.memberUsage || 0} />
                <StatCard loading={loading} flexDirection="column" Icon={Person} title="" />
            </Box>

            <Box sx={{ width: '75%', margin: '0px auto' }} >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }} >
                    <Select sx={{ minWidth: 150 }} value={daysToRender} onChange={e => setDaysToRender(e.target.value)} >
                        <MenuItem value={7}>Siste 7 dager</MenuItem>
                        <MenuItem value={30}>Siste 30 dager</MenuItem>
                        <MenuItem value={365}>Siste år</MenuItem>
                    </Select>
                </Box>

                <LineChart series={series} yAxisTitle="Antall ganger brukt" />
            </Box>
        </Box>
    )
}

export default CampaignInsights;
