import { useState, useEffect } from 'react';
import { Redirect, Route } from "react-router-dom";

import { auth, onAuthStateChanged } from './firebase'

const AuthRoute = ({ ...props }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, setUser)

        return () => unsubscribe();
    }, [])

    if (user) return <Redirect to="/" />

    return <Route {...props} />
}

export default AuthRoute;
