import { Grid, Box, Typography } from "@mui/material"

const ImageTextRow = ({ title, text, imgSrc, imgAlt, isImgLeft = false }) => {
    const TextGrid = (
        <Grid item xs={12} md={7} >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', margin: '0px auto', height: '100%', width: '75%' }} >
                <Typography variant="h4" align="center" >{title}</Typography>
                {text && <Typography variant="h5" align="center" >{text}</Typography>}
            </Box>
        </Grid>
    )

    const ImgGrid = (
        <Grid item xs={12} md={5} sx={{ textAlign: 'center' }} >
            <img alt={imgAlt} src={imgSrc} style={{ height: '100%' }} />
        </Grid>
    )

    return (
        <Grid container spacing={2} sx={{ p: 2, marginY: 2, backgroundColor: isImgLeft ? '#eaf5ed' : null }} >
            {isImgLeft ? ImgGrid : TextGrid}
            {isImgLeft ? TextGrid : ImgGrid}
        </Grid>
    )
}

export default ImageTextRow;
