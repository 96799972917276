import { useState, useEffect, Fragment } from 'react';
import { Redirect, Route } from "react-router-dom";
import ProgressIndicator from '../components/ProgressIndicator';

import { auth, onAuthStateChanged, getDoc, doc, firestore } from './firebase'

const PrivateRoute = ({ publicComponent, Component, ...props }) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [userStores, setUserStores] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setUser(user);

            if (user && loading) {
                const idTokenResult = await user.getIdTokenResult();
                const admin = !!idTokenResult.claims.isAdmin;

                setIsAdmin(admin);

                if (!admin) {
                    const userStoresDoc = await getDoc(doc(firestore, `userStores/${user.uid}`));

                    setUserStores(userStoresDoc.data());
                }
            }

            if (loading) setLoading(false);
        })

        return () => unsubscribe();
    }, [loading])

    if (loading) return <ProgressIndicator />;

    if (!user) {
        if (publicComponent) return (
            <Fragment>
                <Redirect to="/" />
                <Route {...props} component={publicComponent} />
            </Fragment>
        )
        else return <Redirect to="/" />;
    }

    if (!isAdmin && (!userStores || !Array.isArray(userStores.admin) || userStores.admin.length <= 0)) return <Redirect to="/no-access" />

    return <Route {...props} render={props => <Component {...props} isAdmin={isAdmin} userStores={userStores} />} />
}

export default PrivateRoute;
