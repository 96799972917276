import { useState } from "react";
import { Typography } from '@mui/material';

import confirmDialog from '../../../../helpers/confirmDialog';
import { firestore, updateDoc, doc, arrayRemove, arrayUnion } from '../../../../helpers/firebase';
import CampaignModal from '../../../EditStore/CampaignModal';
import CampaignGroup from "./CampaignGroup";
import { filterCampaigns } from "../../../../helpers/utils";

const Campaigns = ({ data, storeId }) => {
    const [selectedCampaign, setSelectedCampaign] = useState();

    const handleDelete = async index => {
        const confirmed = await confirmDialog("Slett kampanje?", "Er du sikker på at du vil slette denne kampanjen?");

        if (confirmed) {
            const newCampaigns = [...data.campaigns];

            newCampaigns.splice(index, 1);

            await updateDoc(doc(firestore, "stores", storeId), { campaigns: newCampaigns });
        }
    }

    const handleOpenEdit = index => {
        const campaign = { ...data.campaigns[index] };

        delete campaign.index;

        setSelectedCampaign(campaign);
    }

    const handleUnpublish = async index => {
        const confirmed = await confirmDialog("Upubliser kampanje?", "Er du sikker på at du vil fjerne denne kampanjen som publisert?");

        if (confirmed) {
            const newCampaign = { ...data.campaigns[index] };

            await updateDoc(doc(firestore, "stores", storeId), { campaigns: arrayRemove(...[data.campaigns[index]]) });

            newCampaign.published = false;

            await updateDoc(doc(firestore, "stores", storeId), { campaigns: arrayUnion(...[newCampaign]) });
        }
    }

    const { publishedCampaigns, comingCampaigns, expiredCampaigns, draftCampaigns } = filterCampaigns(data?.campaigns);

    return (
        <div>
            <Typography variant="h3" align="center" sx={{ marginBottom: 4 }} color="primary" >Kampanjer</Typography>

            <CampaignGroup
                title="Publiserte kampanjer"
                store={data}
                campaigns={publishedCampaigns}
                onDelete={handleDelete}
                onEdit={handleOpenEdit}
                onUnpublish={handleUnpublish}
                defaultExpanded
            />

            <CampaignGroup
                title="Kommende kampanjer"
                store={data}
                campaigns={comingCampaigns}
                onDelete={handleDelete}
                onEdit={handleOpenEdit}
                defaultExpanded
            />

            <CampaignGroup
                title="Utgått"
                store={data}
                campaigns={expiredCampaigns}
                onDelete={handleDelete}
                onEdit={handleOpenEdit}
            />

            <CampaignGroup
                title="Utkast"
                store={data}
                campaigns={draftCampaigns}
                onDelete={handleDelete}
                onEdit={handleOpenEdit}
            />

            <CampaignModal
                storeId={storeId}
                storeData={data}
                open={!!selectedCampaign}
                handleClose={() => setSelectedCampaign(null)}
                campaign={selectedCampaign}
            />
        </div>
    )
}

export default Campaigns;
