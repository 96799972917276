import { Card, Typography, Box, Button } from '@mui/material'
import React, { Fragment, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { CloudUpload } from '@mui/icons-material';

const ImageDropzone = ({ value, onChange }) => {
    const onDrop = useCallback(acceptedFiles => {
        if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
            onChange(acceptedFiles[0]);
        } else {
            onChange(null);
        }
    }, [onChange])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' })

    return (
        <Card sx={{ backgroundColor: '#518c5d', width: 400 }} >
            {value ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                    <img style={{ height: 124, padding: 16 }} alt="logo" src={typeof value === "object" ? URL.createObjectURL(value) : value} />
                    <Button variant="contained" onClick={() => onChange(null)} >Fjern</Button>
                </Box>
            ) : (
                <Fragment>
                    <Box sx={{ p: 1 }} >
                        <Typography color="white" textAlign="center" >Last opp eget bilde</Typography>
                    </Box>

                        <Box sx={{ margin: 2, backgroundColor: '#ffffff', textAlign: 'center', border: 'solid 1px #979797' }} >
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Box sx={{ p: 2 }} >
                                    <CloudUpload />
                                    <Typography>{isDragActive ? "Slipp filen her ..." : "Dra og slipp bildet, eller velg fil"}</Typography>
                                </Box>
                            </div>
                        </Box>
                </Fragment>
            )}

        </Card>
    )
}

export default ImageDropzone;
