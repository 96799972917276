import { useState, Fragment } from 'react';
import { TextField, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, DialogContent, DialogActions, Button } from '@mui/material';
import { LocalizationProvider, DateTimePicker, LoadingButton } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { v4 as uuidv4 } from 'uuid';

import moment from "moment";
import "moment/locale/nb";
import ImageDropzone from '../../components/ImageDropzone';
import { isNormalInteger } from '../../helpers/utils';
moment.locale("nb");

const CampaignForm = ({ onSave, loading = false, campaign, onPreview }) => {
    const id = campaign?.id || uuidv4();

    const [imageOption, setImageOption] = useState(campaign?.imageOption || "own");
    const [image, setImage] = useState(campaign?.image || null);
    const [text, setText] = useState(campaign?.text || "");
    const [description, setDescription] = useState(campaign?.description || "");
    const [startDate, setStartDate] = useState(campaign?.startDate ? moment(campaign.startDate.seconds * 1000) : moment());
    const [endDate, setEndDate] = useState(campaign?.endDate ? moment(campaign.endDate.seconds * 1000) : moment().add(1, "day"));
    const [type, setType] = useState(campaign?.type || "once");
    const [typeAmount, setTypeAmount] = useState(campaign?.typeAmount ? `${campaign.typeAmount}` : "1");

    const isValidDate = !!startDate && !!endDate && endDate.isAfter(startDate);

    const typeAmountValid = type !== "custom" || (isNormalInteger(typeAmount) && parseInt(typeAmount, 10) > 0);
    const isValid = !!imageOption && text.length > 0 && isValidDate && !!type && (imageOption !== "upload" || !!image) && typeAmountValid;
    const isPublished = !!campaign?.published;

    const getData = () => ({
        id,
        imageOption,
        text,
        description,
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        type,
        typeAmount: type === "custom" ? parseInt(typeAmount) : null,
    })

    const handleSave = (published = isPublished) => {
        const data = getData();

        data.published = published;

        onSave(data, image);
    }

    const handlePublish = () => {
        handleSave(true);
    }

    return (
        <Fragment>
            <DialogContent dividers>
                <Grid container spacing={2} >
                    <Grid item xs={12} >
                        <FormControl component="fieldset" fullWidth >
                            <FormLabel component="legend" required >Bilde</FormLabel>
                            <RadioGroup row sx={{ justifyContent: 'space-between' }} aria-label="image" name="image-radio-buttons" value={imageOption} onChange={e => setImageOption(e.target.value)} >
                                <FormControlLabel value="own" control={<Radio />} label="Bruk egen logo" />
                                <FormControlLabel value="upload" control={<Radio />} label="Last opp eget kampanjebilde" />
                                <FormControlLabel disabled value="default" control={<Radio />} label="Bruk standard kampanjebilde" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    {imageOption === "upload" && (
                        <Grid item xs={12} justifyContent="center" alignItems="center" container >
                            <ImageDropzone value={image} onChange={setImage} />
                        </Grid>
                    )}

                    <Grid item xs={12} >
                        <TextField fullWidth required label="Kampanjetittel" value={text} onChange={e => setText(e.target.value)} />
                    </Grid>

                    <Grid item xs={12} >
                        <TextField fullWidth multiline rows={2} label="Utvidet informasjon" value={description} onChange={e => setDescription(e.target.value)} />
                    </Grid>

                    <Grid container item xs={12} >
                        <FormControl component="fieldset" fullWidth >
                            <FormLabel component="legend" required sx={{ marginBottom: 1 }} error={!isValidDate} >Varighet</FormLabel>

                            <LocalizationProvider dateAdapter={DateAdapter} locale="no" >
                                <Grid container spacing={2} direction="row" justifyContent="space-between" >
                                    <Grid item >
                                        <DateTimePicker
                                            renderInput={(props) => <TextField {...props} />}
                                            label="Startdato"
                                            value={startDate}
                                            onChange={setStartDate}
                                            mask="__.__.____ __:__"
                                        />
                                    </Grid>

                                    <Grid item >
                                        <DateTimePicker
                                            renderInput={(props) => (
                                                <TextField {...props} error={!isValidDate} helperText={!isValidDate ? "Sluttdato må være etter startdato" : null} />
                                            )}
                                            label="Sluttdato"
                                            value={endDate}
                                            onChange={setEndDate}
                                            mask="__.__.____ __:__"
                                            onError={console.log}
                                        />
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} >
                        <FormControl component="fieldset" fullWidth >
                            <FormLabel component="legend" required >Hvor mange ganger kan kunden ta i bruk kampanjen?</FormLabel>
                            <RadioGroup row sx={{ justifyContent: 'space-between' }} aria-label="type" name="type-radio-buttons" value={type} onChange={e => setType(e.target.value)} >
                                <FormControlLabel value="once" control={<Radio />} label="1 gang" />
                                <FormControlLabel value="unlimited" control={<Radio />} label="Ubegrenset" />
                                <FormControlLabel value="custom" control={<Radio />} label="Egendefinert" />

                                {type === "custom" && (
                                    <TextField
                                        sx={{ width: 150 }}
                                        size="small"
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        label="Antall"
                                        value={typeAmount}
                                        onChange={e => setTypeAmount(e.target.value)}
                                        error={!typeAmountValid}
                                        helperText={!typeAmountValid ? "Ugyldig tall" : null}
                                    />
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} >
                        <Typography>* Må fylles ut</Typography>
                    </Grid>

                </Grid>
            </DialogContent>

            <DialogActions>
                {onPreview && <Button disabled={!isValid} onClick={() => onPreview(getData(), image)} >Forhåndsvisning</Button>}
                <LoadingButton
                    sx={{ width: '25%', minWidth: 100 }}
                    variant="outlined"
                    loading={loading}
                    disabled={!isValid}
                    onClick={() => handleSave()}
                >
                    Lagre
                </LoadingButton>
                {!isPublished && (
                    <LoadingButton
                        sx={{ width: '25%', minWidth: 100 }}
                        variant="contained"
                        loading={loading}
                        disabled={!isValid}
                        onClick={handlePublish}
                    >
                        Publiser
                    </LoadingButton>
                )}
            </DialogActions>
        </Fragment>
    )
}

export default CampaignForm;
